import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import useStyles from './B2BarSPA.styles';
import deepClone from '../../../../../../helper/deepClone';
import { ChartJSData, ChartJSOptions } from '../../../../../../interface/ChartData';
import { Product, PSILCAResult, SPAResult } from '../../../../../../interface/Product';
import PSILCAChartNavigation from '../../../../../dropdowns/BaseOptions/PSILCAChartNavigation';
import ResultsTheme from '../../../../style/ResultsTheme';
import { initialOptions } from '../../../../options/InitialOptionValues';
import ChartDownload from '../../../../options/components/ChartDownload';
import ProductComparison from '../../../../options/components/Comparison';
import GraphSetting from '../../../../options/components/GraphSettings';
import { CreateChartTS } from '../../../../util/CreateChartTS';
import { lifeCycleColors, palette1, palette2, palette3, palette4, processColors } from '../../../../../../helper/colors';
import { useFormContext } from '../../../../../forms/FormContext';
import { WeighingMethod, WeighingSet, applyWeight, getWeighingSetValue, weighingMethods } from '../../../../../../helper/weighingMethod';
import Dropdown from '../../../../../dropdowns/Dropdown';
import SPAChartNavigation from '../../../../../dropdowns/BaseOptions/SPAChartNavigation';
import { applyValueType } from '../../../../options/util/applyOptions';
import { useCallback, useEffect, useMemo, useState } from 'react';

interface ColorIndexData {
    label: string;
    identifier?: string;
}

interface ChartData {
    product: Product;
}

function B2BarSPA(props: ChartData) {
    const { formState, setFormState } = useFormContext();
    const classes = useStyles();
    const { product } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>(deepClone(initialOptions || {}));
    const [currentWeighingSet, setCurrentWeighingSet] = useState<WeighingSet>();
    const [selectedValueType, setSelectedValueType] = useState('');

    const [results, setResults] = useState<SPAResult>();

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => { return item.calculationType === 'SOCIAL-SPA' },
        );
        const calcResults = calc?.calculationResults as unknown as SPAResult;
        console.log(calc);
        const weighingMethod = weighingMethods.find((method) => method.name === product.socialData.weighingMethod.name);
        const weighingSet = weighingMethod?.sets.find((set) => set.name === product.socialData.weighingMethod.set);
        setCurrentWeighingSet(weighingSet);
        setResults((calcResults));
    }, [product]);

    const findColorPaletteByName = (name: string): string[] => {
        const palettes = {
            "LCS Colors": lifeCycleColors,
            "Sunset": palette1,
            "Multicolored": palette2,
            "Warm": palette3,
            "Cold": palette4,
        } as Record<string, string[]>;
        return palettes[name] || palettes["LCS Colors"];
    };

    const generateExtendedPalette = (baseColors: string[], totalColors: number): string[] => {
        const extendedPalette = [...baseColors];
        while (extendedPalette.length < totalColors) {
            baseColors.forEach((color) => {
                // Adjust brightness or saturation to generate new colors
                const newColor = adjustColor(color, extendedPalette.length / totalColors);
                extendedPalette.push(newColor);
                if (extendedPalette.length >= totalColors) return;
            });
        }
        return extendedPalette.slice(0, totalColors);
    };

    const adjustColor = (color: string, factor: number): string => {

        const f = parseInt(color.slice(1), 16),
            t = factor < 0 ? 0 : 255,
            p = factor < 0 ? factor * -1 : factor,
            R = f >> 16,
            G = f >> 8 & 0x00FF,
            B = f & 0x0000FF;
        return "#" + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
    };

    const getGraphColorIndex = useCallback(({ label, identifier }: ColorIndexData, palette: string[]) => {
        const hash = `${label}:${identifier}`.split('').reduce((hash, char) => {
            return char.charCodeAt(0) + ((hash << 5) - hash);
        }, 0);
        return Math.abs(hash) % palette.length;
    }, []);

    useEffect(() => {
        console.log(results);

        if (!results || !currentWeighingSet)
            return;


        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);
        const selectedPalette = findColorPaletteByName(formState.colorPalette);
        const spaLifeCycle = results.lifeCycleResults[0]
        const extendedPalette = generateExtendedPalette(selectedPalette);
        
        if (options.scales && options.scales.y && options.scales.y.title) {
            options.scales.y.title.text = "Weighted Score";
        }

        const lifeCycleStages: string[] = [];
        if (results.SPAProcess) {
            lifeCycleStages.push(
                results.SPAProcess.lifeCycleStage.split(":")[0]
            );
            chartData.labels = results.stakeholderResults.map((item) =>
                item.name
                    .split(":")[0]
                    .split(" ")
                    .map((s) => {
                        const s1 = s.split("");
                        s1[0] = s1[0].toUpperCase();
                        return s1.join("");
                    })
                    .join(" ")
            );
        }

        results.lifeCycleResults.forEach((lifeCycle) => {
            const lcs = lifeCycleStages.find(
                (stage) => stage === lifeCycle.lcsName.split(":")[0]
            );
            if (!lcs) {
                lifeCycleStages.push(lifeCycle.lcsName.split(":")[0]);
            }
        });
        if(spaLifeCycle){
            chartData.labels = chartData.labels
                .concat(
                    spaLifeCycle.processResults[0].PSILCAPriorities.map(
                        (item) => item.category.split(":")[0]
                    )
                )
                .filter((value, index, self) => self.indexOf(value) === index);
        }
 
    
        //get SPA calculations per stakeholder
        lifeCycleStages.forEach((lifeCycleStage, index) => {
            results.lifeCycleResults
                .filter((item) => item.lcsName.startsWith(lifeCycleStage))
                .forEach((lcs) => {
                    const set = chartData.datasets.find(
                        (item) =>
                            item.label.toLowerCase() ===
                            lifeCycleStage.toLowerCase()
                    );
                    if (!set) {
                        chartData.datasets.push({
                            label: lifeCycleStage,
                            data: lcs
                                ? chartData.labels.map((stakeholder) => {
                                    return lcs.processResults
                                        .map((process) => {
                                            return process.PSILCAPriorities.filter(
                                                (priority) =>
                                                    priority.category.split(":")[0] ===
                                                    stakeholder
                                            ).reduce((val1, val2) => {
                                                return val1 + applyWeight(val2,'psilca', currentWeighingSet);
                                            }, 0);
                                        })
                                        .reduce((val1, val2) => {
                                            return val1 + val2;
                                        }, 0);
                                })
                                : [],
                            backgroundColor: extendedPalette[index], //"#1B5D82", //colorsrgb,

                            //backgroundColor: colors[index], //random color from array
                            borderColor: "000000",
                        });
                    } else {
                        const vals = chartData.labels.map((stakeholder) => {
                            return lcs.processResults
                                .map((process) => {
                                    return process.PSILCAPriorities.filter(
                                        (priority) =>
                                            priority.category.split(":")[0] === stakeholder
                                    ).reduce((val1, val2) => {
                                        return val1 + applyWeight(val2,'psilca', currentWeighingSet);
                                    }, 0);
                                })
                                .reduce((val1, val2) => {
                                    return val1 + val2;
                                }, 0);
                        });
                        set.data = set.data.map(
                            (item, index) => (item += vals[index])
                        );
                    }
                });
        });
        if (results.SPAProcess) {
            const scores = results.stakeholderResults.map((stakeholder) => {
                return stakeholder.SPAPriorities.reduce(
                    (stakeholderTotal, category) =>
                        stakeholderTotal + applyWeight(category, "sam",currentWeighingSet),
                    0
                );
            });

            const set = chartData.datasets.find(
                (set) =>
                    set.label.toLowerCase() ===
                    results.SPAProcess.lifeCycleStage.split(":")[0].toLowerCase()
            );

            if (set) {
                set.data = set.data.map(
                    (item, index) => (item += scores[index])
                );
            } else {
                chartData.datasets.push({
                    label: results.SPAProcess.lifeCycleStage.split(":")[0],
                    data: results.stakeholderResults.map((stakeholder) => {
                        return stakeholder.SPAPriorities.reduce(
                            (stakeholderTotal, category) =>
                                stakeholderTotal + applyWeight(category, "sam",currentWeighingSet),
                            0
                        );
                    }),
                    backgroundColor: selectedPalette[chartData.datasets.length], //"#1B5D82", //colorsrgb,

                    //backgroundColor: colors[index], //random color from array
                    borderColor: "000000",
                });
            }
        }


        setChartJSData(chartData);
        setChartOptions(options);


    }, [results, product, getGraphColorIndex, formState.colorPalette, currentWeighingSet, selectedValueType]);
    const [applyGraphSettings, setApplyGraphSettings] =
        useState<(options: ChartJSOptions, chartData: ChartJSData) => { options: ChartJSOptions; chartData: ChartJSData; }>();
    const triggerChartUpdate = () => {
        if (!chartJSData.datasets || !chartOptions) {
            return; // Exit early if datasets or options are not properly initialized
        }

        let options = deepClone(chartOptions);
        let chartData = deepClone(chartJSData);
        const applyOptionReturn = applyValueType(selectedValueType, chartData, options,true);
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        if (applyGraphSettings) {
            const { options: updatedOptions } = applyGraphSettings(options, chartData);
            Object.assign(options, updatedOptions);
        }

        CreateChartTS(chartData, options);
    };
    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >
                <Box className='temp-box' />
                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '3rem',
                                        alignItems: 'stretch',

                                    }}
                                >
                                    <SPAChartNavigation product={product} defaultBaseIndex={1} defaultResultIndex={1} />
                                    <ProductComparison />
                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >
                                        <Dropdown
                                            options={['Raw Values', '% Per Lifecycle Stage']}
                                            valueSetter={setSelectedValueType}
                                            value={selectedValueType}
                                            label='Value Type'
                                        />
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            '@media (max-width: 1680px)': {
                                                gap: '1vw',
                                            },
                                        }}
                                        className='bottom-options side-right'
                                    >

                                        <ChartDownload chartData={chartJSData}
                                            chartName={'Total weighted result: all stakeholders - Product System'}
                                            chartType='Bar Graph' />
                                        <GraphSetting
                                            chartOptions={chartOptions}
                                            setApplyGraphSettings={setApplyGraphSettings}
                                            triggerChartUpdate={triggerChartUpdate}
                                            chartData={chartJSData}
                                            chartColorType='process'

                                        />

                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box className={classes.chartSec}>
                            <canvas id='graph1Canvas' className='graph-canvas' />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default B2BarSPA;
