import { FormState } from "../components/forms/FormContext";
import {
    EnvFlowInputLCS,
    EnvProcessInputLCS,
    ProductDetailsData,
    SocialInputsLCS,
} from "../interface/Form";
import { ProductLCSData } from "../interface/Product";
import { StakeholderIndicators } from "../interface/Stakeholder";
import { getAccessTokenFromSessionStorage } from "./StorageHelper";
import { sendRequest } from "./fetchUtil";

interface SubmitCreateProductResponse {
  message: string;
  productId: string;
  productName: string;
}

export const buildProductStages = (formState: FormState): ProductLCSData[] => {
    const lcsList: ProductLCSData[] = [];
    const selectedStages: string[] = formState.selectedLCS;

    const envSelected = formState.envDim as boolean;
    const socialSelected = formState.socialDim as boolean;
    const ecoSelected = formState.ecoDim as boolean;
    if (selectedStages) {
        selectedStages.forEach((lcs) => {
            const newStage: ProductLCSData = {
                stage: lcs,
                value: {
                    environmental: {
                        inputs: {
                            processes: [],
                            emissionFlows: [],
                        },
                        outputs: {
                            emissionFlows: [],
                            wasteFlows: [],
                        },
                    },
                    social: {
                        inputs: [],
                        outputs: [],
                    },
                },
            };
            if (envSelected) {
                const inputProcessesLCS =
          formState.envInputProcesses as EnvProcessInputLCS[];
                if (inputProcessesLCS) {
                    const matchingStage = inputProcessesLCS.find((inputLCS) => {
                        return inputLCS.lcs === lcs;
                    });
                    console.log(matchingStage);
                    if (matchingStage) {
                        newStage.value.environmental.inputs.processes =
              matchingStage.values;
                    }
                }
                const inputEmissionsLCS =
          formState.envInputEmissions as EnvFlowInputLCS[];
                if (inputEmissionsLCS) {
                    const matchingStage = inputEmissionsLCS.find((inputLCS) => {
                        return inputLCS.lcs === lcs;
                    });
                    if (matchingStage) {
                        newStage.value.environmental.inputs.emissionFlows =
              matchingStage.values;
                    }
                }
                const outputEmissionsLCS =
          formState.envOutputEmissions as EnvFlowInputLCS[];
                if (outputEmissionsLCS) {
                    const matchingStage = outputEmissionsLCS.find((inputLCS) => {
                        return inputLCS.lcs === lcs;
                    });
                    if (matchingStage) {
                        newStage.value.environmental.outputs.emissionFlows =
              matchingStage.values;
                    }
                }
                const outputWasteLCS = formState.envOutputWaste as EnvFlowInputLCS[];
                if (outputWasteLCS) {
                    const matchingStage = outputWasteLCS.find((inputLCS) => {
                        return inputLCS.lcs === lcs;
                    });
                    if (matchingStage) {
                        newStage.value.environmental.outputs.wasteFlows =
              matchingStage.values;
                    }
                }
            }
            if (socialSelected) {
                const socialInputLCS =
          formState.socialInputProcesses as SocialInputsLCS[];
                console.log(formState.socialInputProcesses);
                if (socialInputLCS) {
                    const matchingStage = socialInputLCS.find((inputLCS) => {
                        return inputLCS.lcs === lcs;
                    });
                    if (matchingStage) {
       
                        newStage.value.social.inputs = matchingStage.values;
                    }
                }
            }
            if (ecoSelected) {
                const inputProcessesLCS =
          formState.envInputProcesses as EnvProcessInputLCS[];
                if (inputProcessesLCS) {
                    const matchingStage = inputProcessesLCS.find((inputLCS) => {
                        return inputLCS.lcs === lcs;
                    });
                    if (matchingStage) {
                        newStage.value.environmental.inputs.processes =
              matchingStage.values;
                    }
                }
            }
            lcsList.push(newStage);
        });
    }
    return lcsList;
};

export const submitCreateProduct = (
    formState: FormState,
    lcsData: ProductLCSData[]
) => {
    const productData: ProductDetailsData = {
        productName: formState.productName,
        productDesc: formState.productDesc,
        modeling: "Specific", //TODO IMPLEMENT THIS
        dimensions: {
            environmentalDim: formState.envDim,
            socialDim: formState.socialDim,
            economicDim: formState.ecoDim,
        },
        unitOfAnalysis: {
            unit: formState.analysisUnit,
            unitGroup: formState.analysisUnitGroup,
            unitQTY: formState.analysisUnitQTY,
        },
        lifeCycleStages: lcsData,
    };
    if (formState.envDim) {
        productData.environmentalData = {
            assessmentMethod: {
                name: formState.envAssessmentMethodName,
                id: formState.envAssessmentMethod,
            },
            nwSet: formState.envNWSet,
        };
    }
    if (formState.socialDim) {
        if (formState.socialAssessmentMethod === "SPA") {
            productData.socialData = {
                weighingMethod: {
                    name: formState.socialSPAWeighingMethod,
                    set: formState.socialSPAWeighingSet,
                },
            };
        }
    }
    const token = getAccessTokenFromSessionStorage();
    console.log(productData);
    const responseData = sendRequest<SubmitCreateProductResponse>(
        `${process.env.REACT_APP_BACKEND_URL}/product/`,
        "POST",
        JSON.stringify(productData),
        { "Content-Type": "application/json", Authorization: "Bearer " + token }
    );
    return responseData;
};

interface EnvCalculationData {
  lifeCycleStages: ProductLCSData[];
  assessmentMethod: {
    name: string;
    id: string;
  };
  normalizationSet: string;
}
interface SocialPSILCACalculationData {
  lifeCycleStages: ProductLCSData[];
  method: string;

}
interface SocialSPACalculationData {
    lifeCycleStages: ProductLCSData[];
    SPAProcess?: {
      name: string;
      id: string;
      lcs: string;
    };
    method: string;
    stakeholderData?: StakeholderIndicators;
    selectedStakeholders?:  string[],
  }
interface EcoCalculationData {
  lifeCycleStages: ProductLCSData[];
}

export const submitEnvCalculation = async (
    formState: FormState,
    productId: string,
    lcsData: ProductLCSData[]
) => {
    const token = getAccessTokenFromSessionStorage();
    const envCalcData: EnvCalculationData = {
        lifeCycleStages: lcsData,
        assessmentMethod: {
            name: formState.envAssessmentMethodName,
            id: formState.envAssessmentMethod,
        },
        normalizationSet: formState.envNWSet,
    };

    const response: string = await sendRequest<string>(
        `${process.env.REACT_APP_BACKEND_URL}/environmental/calculate/${productId}`,
        "POST",
        JSON.stringify(envCalcData),
        {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        }
    );
    return response;
};

export const submitSocialCalculation = async (
    formState: FormState,
    productId: string,
    lcsData: ProductLCSData[]
) => {
    
    const token = getAccessTokenFromSessionStorage();
    let response = "";
    console.log(formState);

    if(formState && formState.socialAssessmentMethod){
        if(formState.socialAssessmentMethod === "SPA"){
            const socialCalcData: SocialSPACalculationData = {
                lifeCycleStages: lcsData,
                method: "Social Impacts Weighting Method",
            };
            if(formState.selectedSPAProcess){
                socialCalcData.SPAProcess = {
                    id:formState.selectedSPAProcess.id,
                    name:formState.selectedSPAProcess.name,
                    lcs:formState.socialSPALCS,
                }
            }
            if(formState.SPAIndicators){
                socialCalcData.stakeholderData = formState.SPAIndicators;
                socialCalcData.selectedStakeholders = formState.selectedStakeholders;
            }


        
            response= await sendRequest<string>(
                `${process.env.REACT_APP_BACKEND_URL}/social/calcSPA/${productId}`,
                "POST",
                JSON.stringify(socialCalcData),
                {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                }
            );
        }else{
            const socialCalcData: SocialPSILCACalculationData = {
                lifeCycleStages: lcsData,
                method: "Social Impacts Weighting Method",
            };
        
            response= await sendRequest<string>(
                `${process.env.REACT_APP_BACKEND_URL}/social/calcPSILCA/${productId}`,
                "POST",
                JSON.stringify(socialCalcData),
                {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                }
            );
        }
    }

    return response;
};
export const submitEcoCalculation = async (
    formState: FormState,
    productId: string,
    lcsData: ProductLCSData[]
) => {
    const token = getAccessTokenFromSessionStorage();
    const ecoCalcData: EcoCalculationData = {
        lifeCycleStages: lcsData,
    };

    const response: string = await sendRequest<string>(
        `${process.env.REACT_APP_BACKEND_URL}/economic/calculate/${productId}`,
        "POST",
        JSON.stringify(ecoCalcData),
        {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        }
    );
    return response;
};

export const submitCreateProductHandler = (
    formState: FormState,
    setIsLoading: (value: React.SetStateAction<boolean>) => void
) => {
    const lcsData = buildProductStages(formState);
    let envIsDone = true;
    let socialIsDone = true;
    let ecoIsDone = true;
    console.log(lcsData);
    submitCreateProduct(formState, lcsData)
        .then((response) => {
            if (formState.envDim) {
                envIsDone = false;
                submitEnvCalculation(formState, response.productId, lcsData)
                    .then(() => {
                        envIsDone = true;
                        setIsLoading(!(envIsDone && socialIsDone && ecoIsDone));
                    })
                    .catch(() => {});
            }
            console.log(formState.socialDim);

            if (formState.socialDim) {
                socialIsDone = false;
                submitSocialCalculation(formState, response.productId, lcsData)
                    .then(() => {
                        socialIsDone = true;
                        setIsLoading(!(envIsDone && socialIsDone && ecoIsDone));
                    })
                    .catch(() => {});
            }
            if (formState.ecoDim) {
                ecoIsDone = false;
                console.log(formState);
                submitEcoCalculation(formState, response.productId, lcsData)
                    .then(() => {
                        socialIsDone = true;
                        setIsLoading(!(envIsDone && socialIsDone && ecoIsDone));
                    })
                    .catch(() => {});
            }
        })
        .catch(() => {});
};


