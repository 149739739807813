import React, {
    useEffect,
    useState,

} from 'react';

import FormControl from '@mui/material/FormControl';

import { useNavigate } from '@tanstack/react-router';
import Dropdown from '../../Dropdown';
import { Product } from '../../../../interface/Product';

interface Props {
    product: Product;
    defaultBaseIndex?: number,
    defaultResultIndex?: number,
    reverse?: boolean,
}
function SPAChartNavigation(props: Props) {
    const { product, defaultBaseIndex, defaultResultIndex, reverse } = props;
    const [selectedBaseOption, setSelectedBaseOption] = useState<string>('');

    const [selectedResultsLayer, setSelectedResultsLayer] = useState<string>('');
    // const {
    //     selectedBaseOption: prevSelectedBaseOption,
    //     updateSelectedBaseOption, selectedResultsLayer: prevSelectedResultsLayer, updateSelectedResultsLayer,
    // } = useProductStore();

    const navigate = useNavigate();

    const baseOptions = [
        'Total weighted result: all stakeholders',
        'Stakeholder level result',
        'Subcategory level result',
        // 'Indicator level result',
    ];

    const resultsOptions = [
        'Product System',
        'LifeCycle Stage',
        'LifeCycle Substage',
        'Process',
    ];

    const navigationHandler = async (baseOption: string, resultsLayer: string) => {
        if (reverse){
            if (baseOption && resultsLayer) {
                if (
                    baseOption === baseOptions[0]
                ) {
                    switch (resultsLayer) {
                    case 'Product System':
                        await navigate({ to: '../../../../../Social/PSILCA/A1/Bar' });
                        break;
                    case 'LifeCycle Stage':
                        await navigate({ to: '../../../../../Social/PSILCA/A2/Bar' });
                        break;
                    case 'LifeCycle Substage':
                        await navigate({ to: '../../../../../Social/PSILCA/A3/Bar' });
                        break;
                    case 'Process':
                        await navigate({ to: '../../../../../Social/PSILCA/A4/Bar' });
                        break;
                    default:
                        break;
                    }
                } else if (
                    baseOption === baseOptions[1]
                ) {
                    switch (resultsLayer) {
                    case 'Product System':
                        await navigate({ to: '../../../../../Social/PSILCA/B1/Treemap' });
                        break;
                    case 'LifeCycle Stage':
                        await navigate({ to: '../../../../../Social/PSILCA/B2/Bar' });
                        break;
                    case 'LifeCycle Substage':
                        await navigate({ to: '../../../../../Social/PSILCA/B3/Bar' });
                        break;
                    case 'Process':
                        await navigate({ to: '../../../../../Social/PSILCA/B4/Charts' });
                        break;
                    default:
                        break;
                    }
                } else if (
                    baseOption === baseOptions[2]
                ) {
                    switch (resultsLayer) {
                    case 'Product System':
                        await navigate({ to: '../../../../../Social/PSILCA/C1/Bar' });
                        break;
                    case 'LifeCycle Stage':
                        await navigate({ to: '../../../../../Social/PSILCA/C2/Bar' });
                        break;
                    case 'LifeCycle Substage':
                        await navigate({ to: '../../../../../Social/PSILCA/C3/Bar' });
                        break;
                    case 'Process':
                        await navigate({ to: '../../../../../Social/PSILCA/C4/Bar' });
                        break;
                    default:
                        break;
                    }
                } else if (
                    baseOption === baseOptions[3]
                ) {
                    switch (resultsLayer) {
                    case 'Product System':
                        await navigate({ to: '../../../../../Social/PSILCA/D1/Bar' });
                        break;
                    case 'LifeCycle Stage':
                        await navigate({ to: '../../../../../Social/PSILCA/D2/Bar' });
                        break;
                    case 'LifeCycle Substage':
                        await navigate({ to: '../../../../../Social/PSILCA/D3/Bar' });
                        break;
                    case 'Process':
                        await navigate({ to: '../../../../../Social/PSILCA/D4/Bar' });
                        break;
                    default:
                        break;
                    }
                }
            } else if (
                baseOption === baseOptions[4]
            ) {
                switch (resultsLayer) {
                case 'Product System':
                    await navigate({ to: '../../E1/Bar' });
                    break;
                case 'LifeCycle Stage':
                    await navigate({ to: '../../E2/Bar' });
                    break;
                case 'LifeCycle Substage':
                    await navigate({ to: '../../E3/Bar' });
                    break;
                case 'Process':
                    await navigate({ to: '../../E4/Bar' });
                    break;
                default:
                    break;
                }
            }
        } else {
            if (baseOption && resultsLayer) {
                if (
                    baseOption === baseOptions[0]
                ) {
                    switch (resultsLayer) {
                    case 'Product System':
                        await navigate({ to: `/product/${product._id}/Social/SPA/A1/Bar` });
                        break;
                    case 'LifeCycle Stage':
                        await navigate({ to: `/product/${product._id}/Social/SPA/A2/Bar` });
                        break;
                    case 'LifeCycle Substage':
                        await navigate({ to: `/product/${product._id}/Social/SPA/A3/Bar` });
                        break;
                    case 'Process':
                        await navigate({ to: `/product/${product._id}/Social/SPA/A4/Bar` });
                        break;
                    default:
                        break;
                    }
                } else if (
                    baseOption === baseOptions[1]
                ) {
                    switch (resultsLayer) {
                    case 'Product System':
                        await navigate({ to: `/product/${product._id}/Social/SPA/B1/Treemap` });
                        break;
                    case 'LifeCycle Stage':
                        await navigate({ to: `/product/${product._id}/Social/SPA/B2/Bar` });
                        break;
                    case 'LifeCycle Substage':
                        await navigate({ to: `/product/${product._id}/Social/SPA/B3/Bar` });
                        break;
                    case 'Process':
                        await navigate({ to: `/product/${product._id}/Social/SPA/B4/Charts` });
                        break;
                    default:
                        break;
                    }
                } else if (
                    baseOption === baseOptions[2]
                ) {
                    switch (resultsLayer) {
                    case 'Product System':
                        await navigate({ to: `/product/${product._id}/Social/SPA/C1/Bar` });
                        break;
                    case 'LifeCycle Stage':
                        await navigate({ to: `/product/${product._id}/Social/SPA/C2/Bar` });
                        break;
                    case 'LifeCycle Substage':
                        await navigate({ to: `/product/${product._id}/Social/SPA/C3/Bar` });
                        break;
                    case 'Process':
                        await navigate({ to: `/product/${product._id}/Social/SPA/C4/Bar` });
                        break;
                    default:
                        break;
                    }
                } else if (
                    baseOption === baseOptions[3]
                ) {
                    switch (resultsLayer) {
                    case 'Product System':
                        await navigate({ to: `/product/${product._id}/Social/SPA/D1/Bar` });
                        break;
                    case 'LifeCycle Stage':
                        await navigate({ to: `/product/${product._id}/Social/SPA/D2/Bar` });
                        break;
                    case 'LifeCycle Substage':
                        await navigate({ to: `/product/${product._id}/Social/SPA/D3/Bar` });
                        break;
                    case 'Process':
                        await navigate({ to: `/product/${product._id}/Social/SPA/D4/Bar` });
                        break;
                    default:
                        break;
                    }
                }
            } else if (
                baseOption === baseOptions[4]
            ) {
                switch (resultsLayer) {
                case 'Product System':
                    await navigate({ to: `../../E1/Bar` });
                    break;
                case 'LifeCycle Stage':
                    await navigate({ to: `../../E2/Bar` });
                    break;
                case 'LifeCycle Substage':
                    await navigate({ to: `../../E3/Bar` });
                    break;
                case 'Process':
                    await navigate({ to: `../../E4/Bar` });
                    break;
                default:
                    break;
                }
            }
        }
    };

    const baseOptionChangeHandler = (value: string) => {
        setSelectedBaseOption(value);
        navigationHandler(value, selectedResultsLayer).then((result) => {
        }).catch((err) => {
        });
    };
    const resultsLayerChangeHandler = (value: string) => {
        setSelectedResultsLayer(value);
        navigationHandler(selectedBaseOption, value).then((result) => {
        }).catch((err) => {
        });
    };

    // useEffect(() => {
    //     if (selectedBaseOption !== prevSelectedBaseOption) {
    //         updateSelectedBaseOption(selectedBaseOption);
    //     }
    // }, [
    //     selectedBaseOption,
    // ]);

    // useEffect(() => {
    //     if (selectedResultsLayer !== prevSelectedResultsLayer) {
    //         updateSelectedResultsLayer(selectedResultsLayer);
    //     }
    // }, [selectedResultsLayer]);
    useEffect(() => {
        setSelectedBaseOption((defaultBaseIndex && baseOptions[defaultBaseIndex]) || 'Total weighted result: all stakeholders');
        setSelectedResultsLayer((defaultResultIndex && resultsOptions[defaultResultIndex]) || 'Product System');
    }, []);

    return (
        // <ThemeProvider theme={DropdownTheme}>
        <FormControl sx={{ 
            width: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            gap: '1vw',

        }} size='small'>
            <>
                {baseOptions.length > 0 && (
                    <Dropdown
                        options={baseOptions}
                        valueSetter={baseOptionChangeHandler}
                        value={selectedBaseOption}
                    />
                )}
                {resultsOptions.length > 0 && (
                    <Dropdown
                        options={resultsOptions}
                        valueSetter={resultsLayerChangeHandler}
                        value={selectedResultsLayer}
                    />
                )}
            </>
        </FormControl>
        // </ThemeProvider>
    );
}

export default SPAChartNavigation;
