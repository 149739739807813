import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import useStyles from './C4BarPSILCA.styles';
import { ThemeProvider } from '@emotion/react';
import Dropdown from '../../../../../dropdowns/Dropdown';
import deepClone from '../../../../../../helper/deepClone';
import { ChartJSData, ChartJSOptions } from '../../../../../../interface/ChartData';
import { Product, PSILCAResult } from '../../../../../../interface/Product';
import LCSDropdowns from '../../../../../dropdowns/LCSDropdowns';
import ResultsTheme from '../../../../style/ResultsTheme';
import { initialOptions } from '../../../../options/InitialOptionValues';
import ChartDownload from '../../../../options/components/ChartDownload';
import ProductComparison from '../../../../options/components/Comparison';
import GraphSetting from '../../../../options/components/GraphSettings';
import SDGLinkage from '../../../../options/components/SDGLinkage';
import { ApplyOptionReturn, applyValueType } from '../../../../options/util/applyOptions';
import { CreateChartTS } from '../../../../util/CreateChartTS';
import PSILCAChartNavigation from '../../../../../dropdowns/BaseOptions/PSILCAChartNavigation';
import { palette1, palette2, palette3, palette4, socialProcessColors } from '../../../../../../helper/colors';
import { useFormContext } from '../../../../../forms/FormContext';

interface FlowData {
    flow: string;
    data: number[];
}

interface CategoryFlows {
    category: string;
    flows: FlowData[];
}
interface ChartData {
    product: Product;
}

export interface ColorIndexData {
    label: string;
    identifier?: string;
}

function C4BarPSILCA(props: ChartData) {
    const { formState, setFormState } = useFormContext();
    const classes = useStyles();
    const { product } = props;
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>();
    const [selectedValueType, setSelectedValueType] = useState<string>('');
    const [selectedFilterOption, setSelectedFilterOption] = useState<string>('');
    const [results, setResults] = useState<PSILCAResult[]>([]);
    const [selectedLCS, setSelectedLCS] = useState<string>('');
    const [selectedLCSS, setSelectedLCSS] = useState<string>('');
    const [selectedProcess, setSelectedProcess] = useState<string>('');
    const [selectedStakeholder, setSelectedStakeholder] = useState<string>('');

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => {return item.calculationType === 'SOCIAL-PSILCA'},
        );
        const calcResults = calc?.calculationResults as PSILCAResult[];
        setResults((calcResults));
    }, [product]);

    const findColorPaletteByName = (name: string): string[] => {
        const palettes = {
            "Process Colors": socialProcessColors,
            "Sunset": palette1,
            "Multicolored": palette2,
            "Warm": palette3,
            "Cold": palette4,
        } as Record<string, string[]>;
        return palettes[name] || palettes["Process Colors"];
    };

    const generateExtendedPalette = (baseColors: string[], totalColors: number): string[] => {
        const extendedPalette = [...baseColors];
        while (extendedPalette.length < totalColors) {
            baseColors.forEach((color) => {
                const newColor = adjustColor(color, extendedPalette.length / totalColors);
                extendedPalette.push(newColor);
                if (extendedPalette.length >= totalColors) return;
            });
        }
        return extendedPalette.slice(0, totalColors);
    };

    const adjustColor = (color: string, factor: number): string => {
        const f = parseInt(color.slice(1), 16),
            t = factor < 0 ? 0 : 255,
            p = factor < 0 ? factor * -1 : factor,
            R = f >> 16,
            G = f >> 8 & 0x00FF,
            B = f & 0x0000FF;
        return "#" + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
    };

    const getGraphColorIndex = useCallback(({ label, identifier }: ColorIndexData, palette: string[]) => {
        const hash = `${label}:${identifier}`.split('').reduce((hash, char) => {
            return char.charCodeAt(0) + ((hash << 5) - hash);
        }, 0);
        return Math.abs(hash) % palette.length;
    }, []);

    useEffect(() => {
        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);

        if (options.scales && options.scales.y && options.scales.y.title) {
            options.scales.y.title.text = "Medium Risk Hours";
        }
        
        let processes: string[] = [];
        const lifeCycle = results[0].subcategories[0].indicators[0]
            .indicatorResults.find((item) => {return item.lcsName === `${selectedLCS}:${selectedLCSS}`});
        if (lifeCycle) {
            processes = lifeCycle.processResults.processes.map((item) => {return item.process});
        }
        let stakeholders: PSILCAResult[] = results;

        if (
            selectedStakeholder !== 'All Stakeholders'
            && !!selectedStakeholder
        ) {
            const currentStakeholder = stakeholders.find(
                (stakeholder) => {return stakeholder.stakeholder === selectedStakeholder},
            );
            if (currentStakeholder) {
                stakeholders = [currentStakeholder];
            }
        }
        const currentProcess = processes.find(
            (item) => {return item === selectedProcess.split(' - ')[0]},
        );
        if (currentProcess) {
            processes = [currentProcess];
        }
        const subcategories = stakeholders.reduce(
            (_subcategories: { stakeholder: string, subcategory: string }[], stakeholder) => {return _subcategories.concat(
                stakeholder.subcategories.map((item) => {return {
                    stakeholder: stakeholder.stakeholder,
                    subcategory: item.subcategory,
                }}),
            )},
            [],
        );
        chartData.labels = subcategories.map(
            (item) => {return item.subcategory},
        );

        // const getGraphColorIndex = (item : ColorIndexData) => {
        //     let colorIndex;
        //     graphColors.forEach((item2, index) => {
        //         if (
        //             item2.label === item.label &&
        //         (item.identifier ? item2.identifier === item.identifier : true)
        //         ) {
        //             colorIndex = index;
        //         }
                
        //     });

        //     if (!colorIndex) {
        //         if (
        //             !graphColors.find(
        //                 (item2) =>
        //                     item2.label === item.label &&
        //             (item.identifier ? item2.identifier === item.identifier : true)
        //             )
        //         ) {
        //             colorIndex = graphColors.push(item) - 1;
        //             setGraphColors(graphColors);
        //             setFormState({
        //                 ...formState,
        //                 processColorIndexes : graphColors,
        //             });
        //         }
        //     }
        
        //     return colorIndex;
        // };
        const selectedPalette = findColorPaletteByName(formState.colorPalette);
        // const totalProcesses = matchingLCS.processResults.processes.length;
        const extendedPalette = generateExtendedPalette(selectedPalette);

        processes.forEach((process, index) => {

            const colorIndex = getGraphColorIndex({
                label: process,
            }, extendedPalette);

            chartData.datasets.push({
                label: process,
                data: stakeholders.reduce((data: number[], stakeholder) => {return data.concat(
                    stakeholder.subcategories.map((subcategory) => {return subcategory.indicators
                        .map((indicator) => {
                            const matchingLCS = indicator.indicatorResults
                                .find(
                                    (lcs) => {return lcs.lcsName === `${selectedLCS}:${selectedLCSS}`},
                                );
                            return matchingLCS?.processResults.processes.find(
                                (item) => {return item.process === process},
                            )?.flowResults.reduce((a, b) => {return a + b.sum}, 0) || 0;
                        })
                        .reduce((a, b) => {return a + b}, 0)}),
                )}, []),
                backgroundColor: extendedPalette[colorIndex],
                // backgroundColor:
                //   currentPalette[
                //   getGraphColorIndex({
                //     label: process,
                //     // identifier: lifeCycle.lcsName,
                //   })
                //   ],

                // manyColors[index], //random color from array
                // borderColor: "000000",
                barPercentage: 1, 
                categoryPercentage: 0.85,
            });
        });

        if (currentProcess) {
            const items = chartData.datasets[0].data?.map((item, index) => {return {
                value: item,
                category: chartData.labels[index],
            }})
                .sort((a, b) => {return b.value - a.value}) || [];

            if (selectedFilterOption === 'Top 10 Contribution') {
                chartData.datasets[0].data = items
                    .slice(0, 10)
                    .map((item) => {return item.value});
                chartData.labels = items
                    .slice(0, 10)
                    .map((item) => {return item.category});
            } else if (selectedFilterOption === 'Top 3 Contribution') {
                chartData.datasets[0].data = items
                    .slice(0, 3)
                    .map((item) => {return item.value});
                chartData.labels = items
                    .slice(0, 3)
                    .map((item) => {return item.category});
            }
        }

        console.log(chartData);
        setChartJSData(chartData);
        setChartOptions(options);
    }, [selectedValueType, selectedFilterOption, selectedCategories, 
        selectedLCS, selectedLCSS, selectedProcess, formState.colorPalette, getGraphColorIndex]);

    const [applyGraphSettings, setApplyGraphSettings] =
    useState<(options: ChartJSOptions, chartData: ChartJSData) => { options: ChartJSOptions; chartData: ChartJSData; }>();    
    const triggerChartUpdate = () => {
        let options = deepClone(chartOptions) || {};
        let chartData = deepClone(chartJSData);
        let applyOptionReturn : ApplyOptionReturn;
        if(selectedValueType === "% Per Subcategory"){
            applyOptionReturn = applyValueType(selectedValueType, chartData, options, false, 'pie');

        }else{
            applyOptionReturn = applyValueType(selectedValueType, chartData, options, true);
        }
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        if (applyGraphSettings) {
            ({ options, chartData } = applyGraphSettings(options, chartData));
        }
        CreateChartTS(chartData, options);
    };

    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >
                {/* <Box className='temp-box' /> */}
                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '3rem',
                                        alignItems: 'stretch',

                                    }}
                                >
                                    <PSILCAChartNavigation product={product} defaultBaseIndex={2} defaultResultIndex={3} />
                                    <SDGLinkage
                                        dimension='PSILCA'
                                        charttype='Table'
                                        setting='C4'
                                    />
                                    <ProductComparison />
                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >
                                        <LCSDropdowns
                                            product={product}
                                            selectedLCS={selectedLCS}
                                            setSelectedLCS={setSelectedLCS}
                                            selectedLCSS={selectedLCSS}
                                            setSelectedLCSS={setSelectedLCSS}
                                            selectedProcess={selectedProcess}
                                            setSelectedProcess={setSelectedProcess}
                                            dimension="Social"
                                            defaultProcessOptions={['All Processes']}
                                        />
                                        <Dropdown
                                            options={['Raw Values', '% Per Subcategory', '% Per Process']}
                                            value={selectedValueType}
                                            valueSetter={setSelectedValueType}
                                        />
                                        <Dropdown
                                            options={['Total Contributions', 'Top 3 Contributions', 'Top 10 Contributions']}
                                            value={selectedFilterOption}
                                            valueSetter={setSelectedFilterOption}
                                        />
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            '@media (max-width: 1680px)': {
                                                gap: '1vw',
                                            },
                                        }}
                                        className='bottom-options side-right'
                                    >
                                        <ChartDownload chartData = {chartJSData} chartName={'Subcategory level result - Process'} chartType='Bar Graph' />
                                        <GraphSetting
                                            chartOptions={chartOptions}
                                            setApplyGraphSettings={setApplyGraphSettings}
                                            triggerChartUpdate={triggerChartUpdate}
                                            chartData = {chartJSData}
                                            chartColorType = 'process'
                                            graphType='barindicator'
                                        />

                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box className={classes.chartSec}>
                            <canvas id='graph1Canvas' className='graph-canvas' />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default C4BarPSILCA;
