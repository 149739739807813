import React, { useContext, useEffect, useMemo, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import {
    styled,
    ThemeProvider,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import Button from '@mui/material/Button';
import { Unstable_Popup as Popup } from '@mui/base/Unstable_Popup';

import Popper, { PopperProps } from '@mui/material/Popper';
import { FormApi, useForm } from '@tanstack/react-form';
import { useFormContext } from '../../../../../forms/FormContext';
import { SocialInputsForm, SocialInputProcess, SocialInputsLCS } from '../../../../../../interface/Form';
import { SelectProcess } from '../../../../../../interface/Process';
import AddButton from '../../../../../style/components/AddButton';
import TableTheme from '../../../../../style/components/TableTheme';
import SelectSocialProcessPopup from '../../../components/SelectSocialProcessPopup';
import { SocialInputProcessListItem } from '../../../SocialPSILCAInputs/SocialInputProcessListItem';




function SocialSPAInputProcessList({
    form,
    lifeCycleStage,
    spaOn,
}: {
    form: FormApi<SocialInputsForm>;
    lifeCycleStage: string;
    spaOn?: boolean;
}) {
    const [showSelectProcessPopup, setShowSelectProcessPopup] = useState<null | HTMLElement>(
        null
    );

    const { formState, setFormState } = useFormContext();

    const [currentProcesses, setCurrentProcesses] = useState<
        SocialInputProcess[]
    >([]);

    const addNewProcess = (process: SelectProcess) => {
        if (process) {
            const newIndex = currentProcesses.length;
            const newProcess = {
                index: newIndex,
                endProductName: '',
                processId: process.refId || process.id,
                processName: process.name + ' - ' + process.location,
                processQuantity: 1,
                processComment: '',
                category: process.category,

            };
            setCurrentProcesses(currentProcesses.concat(newProcess));
        }
    };

    //fetch stages from formstate
    const loadInputs = () => {
        let stages: SocialInputsLCS[] = [];
        if (formState && formState.socialInputProcesses) {
            stages = formState.socialInputProcesses as SocialInputsLCS[];
        }
        return stages;
    };

    //load processes from matching lcs from tanstack form
    const loadProcesses = () => {
        let stages: SocialInputsLCS[] = [];
        if (lifeCycleStage) {
            stages = form.getFieldValue('socialInputProcesses');
            if (stages && stages.length > 0) {
                const matchingLCS = stages.find((stage) => { return stage.lcs === lifeCycleStage });

                setCurrentProcesses(matchingLCS?.values || []);
            }

        }
        return stages;
    };

    useEffect(() => {
        if (lifeCycleStage) {
            const listStages = loadProcesses();

            //if process list from form is empty, try to load it from formState
            if (listStages.length === 0) {
                const stages: SocialInputsLCS[] = loadInputs();
                const matchingLCS = stages.find(
                    (stage) => { return stage.lcs === lifeCycleStage }
                );
                if (matchingLCS) {
                    setCurrentProcesses(matchingLCS.values);
                } else {
                    setCurrentProcesses([]);
                }
            }
        }
    }, [lifeCycleStage]);
    ;

    const [selectHandler, setSelectHandler] = useState<
        (process: SelectProcess) => void
            >(() => { return addNewProcess });

    const showValues = () => {
        const processes = form.getFieldValue('socialInputProcesses');
        console.log(processes);
        console.log(formState);
        console.log(form.state.values);
    };

    useEffect(() => {
        if (currentProcesses.length > 0) {
            const stages = form.getFieldValue('socialInputProcesses');
            if (stages) {
                const matchingStage = stages.find(
                    (stage) => { return stage.lcs === lifeCycleStage }
                );
                if (matchingStage) {
                    matchingStage.values = currentProcesses;
                } else {
                    stages.push({ lcs: lifeCycleStage, values: currentProcesses });
                }
            }
            form.setFieldValue('socialInputProcesses', stages);
            setFormState({...formState, socialInputProcesses:stages });

        }
        
    }, [currentProcesses]);

    const open = Boolean(showSelectProcessPopup);
    const processPopper = open ? 'process-popper' : undefined;

    const openProcessSelect = (event: React.MouseEvent<HTMLElement>) => {
        setShowSelectProcessPopup(showSelectProcessPopup ? null : event.currentTarget);
    };
    const selectAddedProcessHandler = (event: React.MouseEvent<HTMLElement>) => {
        setSelectHandler(() => { return addNewProcess });
        openProcessSelect(event);
    };

    const InputTableCell = styled(TableCell)(({ theme }) => {
        return {
            width: '-webkit-fill-available',
            fontSize: '0.7vw',
            lineHeight: '1.1vw',
            padding: '5px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            [`&.${tableCellClasses.head}`]: {
                backgroundColor: '#004021',
                color: 'white',
                height: '3.5vw',
            },
            [`&.${tableCellClasses.body}`]: {
                // fontSize: 14,
            },
            '@media (max-width: 1680px)': {
                padding: '5px',
                // fontSize: 10,
                // lineHeight: '15px',
            },
            '&:last-of-type': {
                width: '-webkit-fill-available',
                padding: '0',
                display: 'flex',
                justifyContent: 'center',
                color: '#004021',
                // height: '5.5rem',
                '@media (max-width: 1680px)': {
                    // width: '7rem',
                },
            },
        }
    });


    const deleteHandler = (processIndex: number) => {
        setCurrentProcesses(currentProcesses.filter((item, index) => { return index != processIndex }));

    }

    return (
        <ThemeProvider theme={TableTheme}>
            <Box>
                <button type='button' onClick={showValues}
                    style={{ position: 'absolute' }}

                >
                    TEST
                </button>

                <TableContainer
                    sx={{
                        width: '-webkit-fill-available',
                    }}
                    className='spa-inputs-table'
                >
                    <Table>
                        <TableHead>
                            <TableRow
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 2fr 1fr 2vw',
                                }}
                            >
                                <InputTableCell align='center'>End Product</InputTableCell>
                                <InputTableCell align='center'>Process</InputTableCell>
                                {/* {spaOn && (
                                    <InputTableCell align='center'>
                                    Amount (USD value) per one unit of analysis
                                    </InputTableCell>
                                )} */}
                                <InputTableCell align='center'>Comment</InputTableCell>
                                <InputTableCell align='center'> </InputTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody
                            sx={{
                                display: 'flex',
                                overflowY: 'auto',
                                maxHeight: '32vh',
                                flexDirection: 'column',
                                '::-webkit-scrollbar': {
                                    width: '0.2em',
                                },
                                '::-webkit-scrollbar-track': {
                                    background: '#939598',
                                    border: 0,
                                },
                                '::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#32463e',
                                    border: 0,
                                },
                            }}
                        >
                            {currentProcesses.map((process, index) => {
                                return (
                                    <SocialInputProcessListItem
                                        key={process.processName + index.toString()}
                                        socialForm={form}
                                        index={index}
                                        openProcessSelect={openProcessSelect}
                                        setSelectHandler={setSelectHandler}
                                        process={process}
                                        lifeCycleStage={lifeCycleStage}
                                        deleteHandler={deleteHandler}
                                        spaOn
                                    />
                                )
                            })}
                            {/* <SocialInputProcessListItemTemp /> */}
                            <Popper
                                id={processPopper}
                                open={open}
                                anchorEl={showSelectProcessPopup}
                                disablePortal
                                style={{
                                    position: 'fixed',
                                    transform: 'none !important',
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'fixed !important',
                                        transform: 'none !important',
                                        top: '0 !important',
                                        left: '0 !important',
                                        zIndex: 1300,
                                        width: '100%',
                                        height: '-webkit-fill-available',
                                        backgroundColor: 'rgba(0, 0, 0, 0.54)',
                                    }}
                                >
                                    <SelectSocialProcessPopup
                                        selectHandler={selectHandler}
                                        openProcessSelect={openProcessSelect}
                                    />
                                </Box>
                            </Popper>
                        </TableBody>
                    </Table>
                </TableContainer>
                <ThemeProvider theme={AddButton}>
                    <Button
                        size='small'
                        id='process-popup'
                        onClick={(e) => {
                            selectAddedProcessHandler(e);
                        }}
                    >
                        Add
                        <AddCircleIcon />
                    </Button>
                </ThemeProvider>
            </Box>
        </ThemeProvider>
    );
}

export default SocialSPAInputProcessList;
