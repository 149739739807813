import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";
import MiniButtonSelectsTheme from "../../../../style/components/MiniButtonSelects";
import { useNavigate } from "@tanstack/react-router";

import BarGraphImg from "../../../../../uploads/images/graph-icons/Graph-Icon-Bar Graph.png";
import PieGraphImg from "../../../../../uploads/images/graph-icons/Graph-Icon-Pie Chart.png";
import StackedBarGraphImg from "../../../../../uploads/images/graph-icons/Graph-Icon-Stacked Bar Graph.png";
import SunburstGraphImg from "../../../../../uploads/images/graph-icons/Graph-Icon-Sunburst Graph.png";
import TreemapGraphImg from "../../../../../uploads/images/graph-icons/Graph-Icon-Treemap Chart.png";
import RadarGraphImg from "../../../../../uploads/images/graph-icons/Graph-Icon-Radar Chart.png";
import SDGGraphImg from "../../../../../uploads/images/graph-icons/Graph-Icon-SDG-Table.png";

interface GraphTypeProps {
  dimension: string;
  chart: string;
  type: string;
  sdgOn?: boolean;
}

function GraphType(props: GraphTypeProps) {
    const { chart, dimension, type, sdgOn } = props;
    const navigate = useNavigate();

    const chartTypePSILCA = dimension === "PSILCA";
    const chartTypeEconomic = dimension === "Economic";
    const chartTypeSPA = dimension === "SPA";

    const chartTypeBar =
    (chartTypePSILCA && chart === "A4") ||
    (chartTypeEconomic && chart === "A2") ||
    chart === "A3" ||
    chart === "A4" ||
    (chartTypeSPA && chart !== "B1" && !(sdgOn && chart === "C4")) ||
    (sdgOn && chart === "C1" && (chartTypePSILCA || chartTypeSPA)) ||
    (sdgOn && chartTypePSILCA && chart === "C4");

    const chartTypePie =
    chart === "B1" ||
    (chart === "A4" && chartTypeSPA) ||
    (chartTypePSILCA && chart === "A4") ||
    (chartTypeEconomic && chart === "A2") ||
    chart === "A3" ||
    chart === "A4";

    const chartTypeStackedBar =
    (chart === "A4" && chartTypePSILCA) ||
    ((chart === "A4" || chart === "C1") && chartTypeSPA) ||
    (chartTypeEconomic && chart === "A2") ||
    chart === "A3" ||
    (sdgOn && chart === "C1" && (chartTypePSILCA || chartTypeSPA));

    const chartTypeSunburst =
    chartTypeSPA && chart === "C4" || (sdgOn && chartTypeSPA && chart === "C4");

    const chartTypeTreemap = chart === "B1";

    const chartTypeRadar = chartTypeSPA && chart === "C4" && !sdgOn;

    const chartTypeSDG =
    chart === "C4" && sdgOn && (dimension === "SPA" || dimension === "PSILCA");

    const chartTypeSDGProductSys =
    sdgOn &&
    (chart === "C1" || chartTypeBar || chartTypeStackedBar) &&
    (dimension === "SPA" || dimension === "PSILCA");

    return (
        <ThemeProvider theme={MiniButtonSelectsTheme}>
            <Box className="mini-button-wrapper">
                {chartTypeBar && (
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            if (sdgOn) {
                                navigate({
                                    to: `../../../../${dimension}/${chart}/SDG/Bar`,
                                });
                            } else {
                                navigate({
                                    to: `../../../${dimension}/${chart}/Bar`,
                                });
                            }
                        }}
                        disabled={type === "bar" ? true : false}
                    >
                        <img
                            src={BarGraphImg}
                            alt="Icon of bar graph"
                            style={{ width: "1.2vw", objectFit: "contain" }}
                        />
                    </Button>
                )}

                {chartTypePie && (
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            navigate({
                                to: `../../../${dimension}/${chart}/Pie`,
                            });
                        }}
                        disabled={type === "pie" ? true : false}
                    >
                        <img
                            src={PieGraphImg}
                            alt="Icon of pie graph"
                            style={{ width: "1.2vw", objectFit: "contain" }}
                        />
                    </Button>
                )}

                {chartTypeStackedBar && (
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            if (sdgOn) {
                                navigate({
                                    to: `../../../../${dimension}/${chart}/SDG/StackedBar`,
                                });
                            } else {
                                navigate({
                                    to: `../../../${dimension}/${chart}/StackedBar`,
                                });
                            }
                        }}
                        disabled={type === "stacked" ? true : false}
                    >
                        <img
                            src={StackedBarGraphImg}
                            alt="Icon of stacked bar graph"
                            style={{ width: "1.2vw", objectFit: "contain" }}
                        />
                    </Button>
                )}

                {chartTypeSunburst && (
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            if (sdgOn) {
                                navigate({
                                    to: `../../../../${dimension}/${chart}/SDG/Sunburst`,
                                });
                            } else {
                                navigate({
                                    to: `../../../${dimension}/${chart}/Sunburst`,
                                });
                            }
                        }}
                        disabled={type === "sunburst" ? true : false}
                    >
                        <img
                            src={SunburstGraphImg}
                            alt="Icon of sunburst graph"
                            style={{ width: "1.2vw", objectFit: "contain" }}
                        />
                    </Button>
                )}

                {chartTypeTreemap && (
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            navigate({
                                to: `../../../${dimension}/${chart}/Treemap`,
                            });
                        }}
                        disabled={type === "tree" ? true : false}
                    >
                        <img
                            src={TreemapGraphImg}
                            alt="Icon of treemap chart"
                            style={{ width: "1.2vw", objectFit: "contain" }}
                        />
                    </Button>
                )}

                {chartTypeRadar && (
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            navigate({
                                to: `../../../${dimension}/${chart}/Radar`,
                            });
                        }}
                        disabled={type === "radar" ? true : false}
                    >
                        <img
                            src={RadarGraphImg}
                            alt="Icon of radar graph"
                            style={{ width: "1.2vw", objectFit: "contain" }}
                        />
                    </Button>
                )}
                {chartTypeSDG && (
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            if (sdgOn) {
                                navigate({
                                    to: `../../../../${dimension}/${chart}/SDG/Table`,
                                });
                            } else {
                                navigate({
                                    to: `../../../${dimension}/${chart}/SDG/Table`,
                                });
                            }
                        }}
                        disabled={type === "sdg" ? true : false}
                    >
                        <img
                            src={SDGGraphImg}
                            alt="Icon of radar graph"
                            style={{ width: "1.2vw", objectFit: "contain" }}
                        />
                    </Button>
                )}
            </Box>
        </ThemeProvider>
    );
}

export default GraphType;
