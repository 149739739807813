import React, { useCallback, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import useStyles from "./C2BarSPA.styles";
import { Bar } from "react-chartjs-2";
// import Loader from '../../components/loader';
import toast from "react-hot-toast";
import deepClone from "../../../../../../helper/deepClone";
import {
    ChartJSData,
    ChartJSOptions,
} from "../../../../../../interface/ChartData";
import SPAChartNavigation from "../../../../../dropdowns/BaseOptions/SPAChartNavigation";
import ResultsTheme from "../../../../style/ResultsTheme";
import { initialOptions } from "../../../../options/InitialOptionValues";
import ChartDownload from "../../../../options/components/ChartDownload";
import ProductComparison from "../../../../options/components/Comparison";
import GraphSetting from "../../../../options/components/GraphSettings";
import { applyValueType } from "../../../../options/util/applyOptions";
import { CreateChartTS } from "../../../../util/CreateChartTS";
import {
    SPAPSILCAPriorityResult,
    Product,
    SPALCSProcessResult,
    SPALifeCycleResult,
    SPAResult,
    SPAStakeholderResult,
} from "../../../../../../interface/Product";
import Dropdown from "../../../../../dropdowns/Dropdown";
import {
    lifeCycleColors,
    palette1,
    palette2,
    palette3,
    palette4,
    productPerformanceColor,
    socialProcessColors,
} from "../../../../../../helper/colors";
import { useFormContext } from "../../../../../forms/FormContext";
import {
    applyWeight,
    weighingMethods,
} from "../../../../../../helper/weighingMethod";

interface ColorIndexData {
  label: string;
  identifier?: string;
}

interface ChartData {
  product: Product;
}

function C2BarSPA(props: ChartData) {
    const { formState, setFormState } = useFormContext();
    const classes = useStyles();
    const { product } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({
        datasets: [],
        labels: [],
    });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>();
    const [selectedValueType, setSelectedValueType] = useState<string>("");
    const [selectedStakeholder, setSelectedStakeholder] = useState<string>("");
    const [stakeholderOptions, setStakeholderOptions] = useState<string[]>([]);
    const [currentWeighingSet, setCurrentWeighingSet] = useState<WeighingSet>();

    const [results, setResults] = useState<SPAResult>();

    useMemo(() => {
        const calc = product.calculations.find((item) => {
            return item.calculationType === "SOCIAL-SPA";
        });
        const calcResults = calc?.calculationResults as unknown as SPAResult;

        setResults(calcResults);
        const weighingMethod = weighingMethods.find(
            (method) => method.name === product.socialData.weighingMethod.name
        );
        const weighingSet = weighingMethod?.sets.find(
            (set) => set.name === product.socialData.weighingMethod.set
        );
        setCurrentWeighingSet(weighingSet);
        setStakeholderOptions(
            ["All Stakeholders"].concat(
                calcResults.lifeCycleResults[0].processResults[0].PSILCAPriorities.map(
                    (item) => {
                        return item.category.split(":")[0]
                    }
                ).reduce((list : string[], stakeholder) =>{
                    if(!list.find(item => item === stakeholder)){
                        list.push(stakeholder);
                    }
                    return list;
                } ,[])
            )
        );
    }, [product]);

    const findColorPaletteByName = (name: string): string[] => {
        const palettes = {
            "LCS Colors": lifeCycleColors,
            Sunset: palette1,
            Multicolored: palette2,
            Warm: palette3,
            Cold: palette4,
        };
        return palettes[name] || palettes["LCS Colors"];
    };

    const generateExtendedPalette = (
        baseColors: string[],
        totalColors: number
    ): string[] => {
        const extendedPalette = [...baseColors];
        while (extendedPalette.length < totalColors) {
            baseColors.forEach((color) => {
                // Adjust brightness or saturation to generate new colors
                const newColor = adjustColor(
                    color,
                    extendedPalette.length / totalColors
                );
                extendedPalette.push(newColor);
                if (extendedPalette.length >= totalColors) return;
            });
        }
        return extendedPalette.slice(0, totalColors);
    };

    const adjustColor = (color: string, factor: number): string => {
        const f = parseInt(color.slice(1), 16),
            t = factor < 0 ? 0 : 255,
            p = factor < 0 ? factor * -1 : factor,
            R = f >> 16,
            G = (f >> 8) & 0x00ff,
            B = f & 0x0000ff;
        return (
            "#" +
      (
          0x1000000 +
        (Math.round((t - R) * p) + R) * 0x10000 +
        (Math.round((t - G) * p) + G) * 0x100 +
        (Math.round((t - B) * p) + B)
      )
          .toString(16)
          .slice(1)
        );
    };

    const getGraphColorIndex = useCallback(
        ({ label, identifier }: ColorIndexData, palette: string[]) => {
            const hash = `${label}:${identifier}`.split("").reduce((hash, char) => {
                return char.charCodeAt(0) + ((hash << 5) - hash);
            }, 0);
            return Math.abs(hash) % palette.length;
        },
        []
    );

    useEffect(() => {
        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);

        if (options.scales && options.scales.y && options.scales.y.title) {
            options.scales.y.title.text = "Weighted Score";
        }

        const selectedPalette = findColorPaletteByName(formState.colorPalette);
        const extendedPalette = generateExtendedPalette(selectedPalette);
        const spaLifeCycle = results.lifeCycleResults[0];
        const stakeholders = results.stakeholderResults;

        const lifeCycleStages: string[] = [];
        if (results.SPAProcess) {
            lifeCycleStages.push(results.SPAProcess.lifeCycleStage.split(":")[0]);
            chartData.labels = results.stakeholderResults.reduce(
                (list: string[], item) => {
                    return list.concat(item.SPAPriorities.map((item2) => item2.category));
                },
                []
            );
        }
        results.lifeCycleResults.forEach((lifeCycle) => {
            const lcs = lifeCycleStages.find(
                (stage) => stage === lifeCycle.lcsName.split(":")[0]
            );
            if (!lcs) {
                lifeCycleStages.push(lifeCycle.lcsName.split(":")[0]);
            }
        });

        chartData.labels = chartData.labels
            .concat(
                spaLifeCycle?.processResults[0].PSILCAPriorities.map(
                    (item) => item.category
                ) || []
            )
            .filter((item, index, self) => self.indexOf(item) === index);

        //Remove duplicates that have different capitalization
        chartData.labels = chartData.labels.reduce((list: string[], label) => {
            const duplicate = list.find(
                (item) => item.toLowerCase() === label.toLowerCase()
            );
            if (!duplicate) {
                list.push(label);
            } else {
                if (label.localeCompare(duplicate)) {
                    const index = list.indexOf(duplicate);
                    if (index !== -1) {
                        list[index] = label;
                    }
                }
            }
            return list;
        }, []);

        if (selectedStakeholder !== "All Stakeholders") {
            chartData.labels = chartData.labels.filter(
                (item) => item.split(":")[0] === selectedStakeholder
            );
        }

        //get SPA calculations per stakeholder
        lifeCycleStages.forEach((lifeCycleStage, index) => {
            const lcsList = results.lifeCycleResults.filter(
                (lcsCalc) => lcsCalc.lcsName.split(":")[0] === lifeCycleStage
            );
            chartData.datasets.push({
                label: lifeCycleStage,
                data: lcsList
                    ? chartData.labels.map((stakeholder) => {
                        return lcsList.reduce((total, item) => {
                            return (
                                total +
                  item.processResults
                      .map((process) => {
                          return process.PSILCAPriorities.filter(
                              (priority) => priority.category === stakeholder
                          ).reduce((val1, val2) => {
                              return (
                                  val1 + applyWeight(val2, "psilca", currentWeighingSet)
                              );
                          }, 0);
                      })
                      .reduce((val1, val2) => {
                          return val1 + val2;
                      }, 0)
                            );
                        }, 0);
                    })
                    : [],
                backgroundColor: selectedPalette[index], //"#1B5D82",//colors[index], //random color from array
                borderColor: "000000",
            });

            if (results.SPAProcess) {
                const SPAlcs = results.SPAProcess.lifeCycleStage;
                if (lifeCycleStage === SPAlcs.split(":")[0]) {
                    const scores = chartData.labels.map((stakeholder) => {
                        const stakeholderCalc = results.stakeholderResults.find(
                            (calc) => calc.name === stakeholder.split(":")[0]
                        );
                        if (stakeholderCalc) {
                            const subcategoryCalc = stakeholderCalc.SPAPriorities.find(
                                (item) => item.category === stakeholder
                            );

                            if (subcategoryCalc) {
                                return applyWeight(subcategoryCalc, "sam", currentWeighingSet);
                            } else {
                                return 0;
                            }
                        } else {
                            return 0;
                        }
                    });
                    let index = 0;
                    const set = chartData.datasets.find((set) => set.label === SPAlcs);
                    if (set) {
                        if (set.data.length === 0) {
                            set.data = scores;
                        } else if (set.data.length > 0) {
                            set.data = scores.map((item) =>
                                item + index <= set.data.length - 1 ? set.data[index++] : 0
                            );
                        }
                        set.data = set.data.map((item) => item + scores[index++]);
                    }
                }
            }
        });

        setChartJSData(chartData);
        setChartOptions(options);
    }, [
        selectedValueType,
        selectedStakeholder,
        getGraphColorIndex,
        formState.colorPalette,
    ]);

    const [applyGraphSettings, setApplyGraphSettings] =
    useState<
      (
        options: ChartJSOptions,
        chartData: ChartJSData
      ) => { options: ChartJSOptions; chartData: ChartJSData }
    >();
    const triggerChartUpdate = () => {
        let options = deepClone(chartOptions) || {};
        let chartData = deepClone(chartJSData);
        const applyOptionReturn = applyValueType(
            selectedValueType,
            chartData,
            options,
            false,
            'pie' 
        );
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        if (applyGraphSettings) {
            ({ options, chartData } = applyGraphSettings(options, chartData));
        }
        CreateChartTS(chartData, options);
    };

    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: "0",
                    maxWidth: "100%",
                    maxHeight: "-webkit-fill-available",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1vw",
                }}
            >
                <Box className="temp-box" />
                <Box component="div" className="results-wrapper">
                    <Box component="div" className="results-options">
                        <Paper square>
                            <Box component="div" className="results-options-top">
                                <FormControl
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "3rem",
                                        alignItems: "stretch",
                                    }}
                                >
                                    <SPAChartNavigation
                                        product={product}
                                        defaultBaseIndex={2}
                                        defaultResultIndex={1}
                                    />
                                    <ProductComparison />
                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component="div" className="results-options-bottom">
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                        }}
                                        className="bottom-options side-left"
                                    >
                                        <Dropdown
                                            options={stakeholderOptions}
                                            valueSetter={setSelectedStakeholder}
                                            value={selectedStakeholder}
                                        />
                                        <Dropdown
                                            options={["Raw Values", "% Per Subcategory"]}
                                            valueSetter={setSelectedValueType}
                                            value={selectedValueType}
                                        />
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            "@media (max-width: 1680px)": {
                                                gap: "1vw",
                                            },
                                        }}
                                        className="bottom-options side-right"
                                    >
                                        <ChartDownload
                                            chartData={chartJSData}
                                            chartName={"Subcategory level result - Product System"}
                                            chartType="Bar Graph"
                                        />
                                        <GraphSetting
                                            chartOptions={chartOptions}
                                            setApplyGraphSettings={setApplyGraphSettings}
                                            triggerChartUpdate={triggerChartUpdate}
                                            chartData={chartJSData}
                                            chartColorType="performance"
                                            graphType="barindicator"
                                        />
                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Box className={classes.chartSec}>
                            <canvas id="graph1Canvas" className="graph-canvas" />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default C2BarSPA;
