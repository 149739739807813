import React, {
    useEffect,
    useState,
    Dispatch,
    SetStateAction,
    SyntheticEvent,
} from 'react';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';
import { Product, EnvironmentalResult } from '../../../interface/Product';
import Dropdown from '../Dropdown';
import ThemeProvider from '@mui/styles/ThemeProvider';
import DropdownTheme from '../../style/components/DropdownTheme';

interface DropDownData {
    product: Product;
    selectedLCS: string;
    setSelectedLCS: Dispatch<SetStateAction<string>>;
    selectedLCSS?: string;
    setSelectedLCSS?: Dispatch<SetStateAction<string>>;
    selectedProcess?: string;
    setSelectedProcess?: Dispatch<SetStateAction<string>>;
    dimension?: string;
    defaultProcessOptions?: string[];
}
function LCSDropdowns(props: DropDownData) {
    const {
        product,
        setSelectedLCS,
        setSelectedLCSS,
        setSelectedProcess,
        selectedLCS,
        selectedLCSS,
        selectedProcess,
        dimension,
        defaultProcessOptions,
    } = props;

    const [lcsOptions, setLCSOptions] = useState<string[]>([]);
    const [lcssOptions, setLCSSOptions] = useState<string[]>([]);
    const [selectableProcesses, setSelectableProcesses] = useState<string[]>([]);

    useEffect(() => {
        if (product) {
            const lcStages: string[] = [];
            product.lifeCycleStages.forEach((lcs) => {
                if (!lcStages.find((item) => {return item === lcs.stage.split(':')[0]})) {
                    lcStages.push(lcs.stage.split(':')[0]);
                }
            });
            setLCSOptions(lcStages);
            if(setSelectedLCS){
                setSelectedLCS(lcStages[0]);

            }
        }
    }, [product]);

    useEffect(() => {
        if (selectedLCS) {
            const lcSubstages = product.lifeCycleStages.reduce(
                (stages: string[], stage) => {
                    if (stage.stage.split(':')[0] === selectedLCS) {
                        return stages.concat(stage.stage.split(':')[1]);
                    }
                    return stages;
                },
                [],
            );
            if (lcSubstages && setSelectedLCSS) {
                setLCSSOptions(lcSubstages);
                setSelectedLCSS(lcSubstages[0]);
            }
        }
    }, [selectedLCS]);

    useEffect(() => {
        if (selectedLCS && selectedLCSS) {
            const processes: string[] = defaultProcessOptions || [];
            const matchingStage = product.lifeCycleStages.find(
                (lcs) => {return lcs.stage === `${selectedLCS}:${selectedLCSS}`},
            );
            if (matchingStage) {
                if (dimension === 'Social') {
                    const dim = matchingStage.value.social;
                    dim.inputs.forEach((process) => {return processes.push(process.processName)});
                } else {
                    const dim = matchingStage.value.environmental;
                    dim.inputs.processes.forEach((process) => {return processes.push(process.processName)});
                    if (dim.inputs.emissionFlows.length > 0) {
                        processes.push('Emission Flows (inputs)');
                    }
                    if (dim.outputs.wasteFlows.length > 0) {
                        processes.push('Waste Flows (outputs)');
                    }
                    if (dim.outputs.emissionFlows.length > 0) {
                        processes.push('Emission Flows (Outputs)');
                    }
                }
            }

            if (processes.length > 0 && setSelectedProcess) {
                setSelectableProcesses(processes);
                setSelectedProcess(processes[0]);
            }
        }
    }, [selectedLCSS]);

    return (
        <ThemeProvider theme={DropdownTheme}>
            <FormControl sx={{ width: '178px' }} size='small'>
                <>
                    {lcsOptions.length > 0 && setSelectedLCS && (
                        <Dropdown
                            options={lcsOptions}
                            value={selectedLCS}
                            valueSetter={setSelectedLCS}
                            defaultValue={selectedLCS}
                            label='Lifecycle Stage'
                        />
                    )}
                    {lcssOptions.length > 0 && setSelectedLCSS && lcssOptions && (
                        <Dropdown
                            options={lcssOptions}
                            valueSetter={setSelectedLCSS}
                            value={selectedLCSS}
                            defaultValue={selectedLCSS}
                            label='Sub Lifecycle Stage'
                        />
                    )}
                    {selectableProcesses.length > 0 && setSelectedProcess && (
                        <Dropdown
                            options={selectableProcesses}
                            value={selectedProcess}
                            valueSetter={setSelectedProcess}
                            label='Selected Process'
                            className='process-name'
                        />
                    )}
                </>
            </FormControl>
        </ThemeProvider>
    );
}

export default LCSDropdowns;
