import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import useStyles from './C4Bar1SPA.styles';
import { Bar } from 'react-chartjs-2';
// import Loader from '../../components/loader';
import toast from 'react-hot-toast';
import deepClone from '../../../../../../helper/deepClone';
import { ChartJSData, ChartJSOptions } from '../../../../../../interface/ChartData';
import SPAChartNavigation from '../../../../../dropdowns/BaseOptions/SPAChartNavigation';
import ResultsTheme from '../../../../style/ResultsTheme';
import { initialOptions } from '../../../../options/InitialOptionValues';
import ChartDownload from '../../../../options/components/ChartDownload';
import ProductComparison from '../../../../options/components/Comparison';
import GraphSetting from '../../../../options/components/GraphSettings';
import { applyValueType } from '../../../../options/util/applyOptions';
import { CreateChartTS } from '../../../../util/CreateChartTS';
import { SPAPSILCAPriorityResult, Product, SPALCSProcessResult, SPALifeCycleResult, SPAResult, SPAStakeholderResult } from '../../../../../../interface/Product';
import Dropdown from '../../../../../dropdowns/Dropdown';
import { palette1, palette2, palette3, palette4, processColors, productPerformanceColor, socialProcessColors } from '../../../../../../helper/colors';
import { useFormContext } from '../../../../../forms/FormContext';
import { WeighingSet, applyWeight, weighingMethods } from '../../../../../../helper/weighingMethod';
import LCSDropdowns from '../../../../../dropdowns/LCSDropdowns';
import ComparisonCompanySector from '../../../../options/components/ComparisonCompanySector';
import GraphType from '../../../../options/components/GraphTypeOptions';
import ResourcesWhitePapersAndPublications from '../../../../../../website/pages/resources/ResourcesWhitePapersAndPublications';

interface ColorIndexData {
    label: string;
    identifier?: string;
}

interface ChartData {
    product: Product;
}

function C4Bar1SPA(props: ChartData) {
    const { formState, setFormState } = useFormContext();
    const classes = useStyles();
    const { product } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>();
    const [selectedValueType, setSelectedValueType] = useState<string>('');
    const [selectedStakeholder, setSelectedStakeholder] = useState<string>('All Stakeholders');
    const [stakeholderOptions, setStakeholderOptions] = useState<string[]>([]);
    const [currentWeighingSet, setCurrentWeighingSet] = useState<WeighingSet>();
    const [selectedLCS, setSelectedLCS] = useState<string>('');
    const [selectedLCSS, setSelectedLCSS] = useState<string>('');
    const [selectedProcess, setSelectedProcess] = useState<string>('');
    const [results, setResults] = useState<SPAResult>();

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => { return item.calculationType === 'SOCIAL-SPA' },
        );
        const calcResults = calc?.calculationResults as unknown as SPAResult;

        setResults((calcResults));
        const weighingMethod = weighingMethods.find((method) => method.name === product.socialData.weighingMethod.name);
        const weighingSet = weighingMethod?.sets.find((set) => set.name === product.socialData.weighingMethod.set);
        setCurrentWeighingSet(weighingSet);
        setStakeholderOptions(
            ["All Stakeholders"].concat(
                calcResults.lifeCycleResults[0].processResults[0].PSILCAPriorities.map(
                    (item) => {
                        return item.category.split(":")[0]
                    }
                ).reduce((list: string[], stakeholder) => {
                    if (!list.find(item => item === stakeholder)) {
                        list.push(stakeholder);
                    }
                    return list;
                }, [])
            )
        );
    }, [product]);


    const findColorPaletteByName = (name: string): string[] => {
        const palettes = {
            "Process Colors": processColors,
            "Sunset": palette1,
            "Multicolored": palette2,
            "Warm": palette3,
            "Cold": palette4,
        };
        return palettes[name] || palettes["Process Colors"];
    };

    const generateExtendedPalette = (baseColors: string[], totalColors: number): string[] => {
        const extendedPalette = [...baseColors];
        while (extendedPalette.length < totalColors) {
            baseColors.forEach((color) => {
                // Adjust brightness or saturation to generate new colors
                const newColor = adjustColor(color, extendedPalette.length / totalColors);
                extendedPalette.push(newColor);
                if (extendedPalette.length >= totalColors) return;
            });
        }
        return extendedPalette.slice(0, totalColors);
    };

    const adjustColor = (color: string, factor: number): string => {

        const f = parseInt(color.slice(1), 16),
            t = factor < 0 ? 0 : 255,
            p = factor < 0 ? factor * -1 : factor,
            R = f >> 16,
            G = f >> 8 & 0x00FF,
            B = f & 0x0000FF;
        return "#" + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
    };

    const getGraphColorIndex = useCallback(({ label, identifier }: ColorIndexData, palette: string[]) => {
        const hash = `${label}:${identifier}`.split('').reduce((hash, char) => {
            return char.charCodeAt(0) + ((hash << 5) - hash);
        }, 0);
        return Math.abs(hash) % palette.length;
    }, []);

    useEffect(() => {
        if(!ResourcesWhitePapersAndPublications)
            return;

        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);

        console.log(results);
        if (options.scales && options.scales.y && options.scales.y.title) {
            options.scales.y.title.text = "Score";
        }

        options.plugins = {
            ...options.plugins,
            datalabels: {
                display: false,
            },
        };

        const selectedPalette = findColorPaletteByName(formState.colorPalette);
        const extendedPalette = generateExtendedPalette(selectedPalette);
        const psilcaLifeCycle = results.lifeCycleResults.find((lcs) => lcs.lcsName === `${selectedLCS}:${selectedLCSS}`);

        let process;
        if (psilcaLifeCycle) {
            process = psilcaLifeCycle.processResults.find(
                (item) => item.processName === selectedProcess.split(" - ")[0]
            );

            const stakeholder = selectedStakeholder;



            if (process) {
                chartData.datasets.push({
                    label: process.processName,
                    data: process.PSILCAResults.filter((result) => {
                        if (
                            stakeholder !== "All Stakeholders" 
                        ) {
                            return (
                                result.category.split(":")[0] === stakeholder
                            );
                        } else {
                            return true;
                        }
                    })
                        .sort(
                            (a, b) =>
                                a.category.localeCompare(b.category)
                        )
                        .map((item2) => item2.score),
                    backgroundColor:
                        selectedPalette[
                            0
                        // getGraphColorIndex({
                        //     label: item.processName,
                        //     identifier: spaLifeCycle.lcsName,
                        // })
                        ], //colors[index], //random color from array
                    borderColor: "000000",
                });

                chartData.labels = process.PSILCAResults.filter(
                    (result) => {
                        if (
                            stakeholder !== "All Stakeholders" 
                        ) {
                            return (
                                result.category.split(":")[0] === stakeholder
                            );
                        } else {
                            return true;
                        }
                    }
                )
                    .sort(
                        (a, b) => a.category.localeCompare(b.category)
                    )
                    .map((item2) => item2.category);


            }

            // if (chartData.datasets.length === 1) {
            //     const indexes: number[] = [];
            //     chartData.datasets[0].data =
            //         chartData.datasets[0].data.filter((value, index) => {
            //             if (value > 0) {
            //                 indexes.push(index);
            //                 return true;
            //             } else {
            //                 return false;
            //             }
            //         });
            //     chartData.labels = chartData.labels.filter(
            //         (item, index) => {
            //             return indexes.find((item) => item === index);
            //         }
            //     );
            // }

        }

        console.log(chartData);
        setChartJSData(chartData);
        setChartOptions(options);
    }, [selectedStakeholder, getGraphColorIndex, formState.colorPalette, selectedLCS, selectedLCSS, selectedProcess]);

    const [applyGraphSettings, setApplyGraphSettings] =
        useState<(options: ChartJSOptions, chartData: ChartJSData) =>
            { options: ChartJSOptions; chartData: ChartJSData; }>();
    const triggerChartUpdate = () => {
        let options = deepClone(chartOptions) || {};
        let chartData = deepClone(chartJSData);
        const applyOptionReturn = applyValueType(selectedValueType, chartData, options);
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        if (applyGraphSettings) {
            ({ options, chartData } = applyGraphSettings(options, chartData));
        }
        CreateChartTS(chartData, options);
    };

    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >
                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Box component='div' className='results-options-top'>
                            <FormControl
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '3rem',
                                    alignItems: 'stretch',

                                }}
                            >
                                {/* <SPAChartNavigation product={product} defaultBaseIndex={2} defaultResultIndex={3} /> */}
                            </FormControl>
                        </Box>
                        <Box component='div' className='results-options-bottom'>
                            <Box>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                    className='bottom-options side-left two-graphs'
                                >
                                    <GraphType
                                        chart='C4'
                                        dimension='SPA'
                                    />
                                    <LCSDropdowns
                                        product={product}
                                        selectedLCS={selectedLCS}
                                        setSelectedLCS={setSelectedLCS}
                                        selectedLCSS={selectedLCSS}
                                        setSelectedLCSS={setSelectedLCSS}
                                        selectedProcess={selectedProcess}
                                        setSelectedProcess={setSelectedProcess}
                                        dimension="Social"
                                    />
                                    <Dropdown
                                        options={stakeholderOptions}
                                        valueSetter={setSelectedStakeholder}
                                        value={selectedStakeholder}

                                    />
                                    {/* <Dropdown
                                        options={['Raw Values', '% Per Subcategory']}
                                        valueSetter={setSelectedValueType}
                                        value={selectedValueType}

                                    /> */}

                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        justifyContent: 'center',
                                        '@media (max-width: 1680px)': {
                                            gap: '1vw',
                                        },
                                        width: 'min-content',
                                    }}
                                    className='bottom-options side-right'
                                >
                                    <ChartDownload chartData={chartJSData}
                                        chartName={'Subcategory level result - Product System'}
                                        chartType='Bar Graph' />
                                    <GraphSetting
                                        chartOptions={chartOptions}
                                        setApplyGraphSettings={setApplyGraphSettings}
                                        triggerChartUpdate={triggerChartUpdate}
                                        chartData={chartJSData}
                                        chartColorType='performance'
                                        graphType='barindicator'
                                        datalabelDisabled
                                    />

                                </FormControl>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Divider />

                <Box className={classes.chartSec}>
                    <canvas id='graph1Canvas' className='graph-canvas' />
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default C4Bar1SPA;
