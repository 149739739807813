import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => {return {
    chartSec: {
        width: '-webkit-fill-available',
        height: '63vh',
    },
    SDGLegendWrap: {
        display: 'flex',
        flexDirection: 'row',
        gap: '1vw',
    },
    
    SDGLegendBox: {
        display: 'flex',
        flexDirection: 'row',
        gap: '5px',
    },
    
    TableHeaderCellImg: {
        padding: '1px',
        lineHeight: '0.5rem !important',
        border: 0,
        minWidth: '5vw !important',
    },
    SDGFont: {
        fontFamily: 'Montserrat, sans-serif !important',
        fontSize: '0.7vw !important',
    },
}});

export default useStyles;
