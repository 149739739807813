/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import useStyles from './B4PiePSILCA.styles';
import deepClone from '../../../../../../helper/deepClone';
import { ChartJSData, ChartJSOptions } from '../../../../../../interface/ChartData';
import { Product, PSILCAResult } from '../../../../../../interface/Product';
import { initialOptions } from '../../../../options/InitialOptionValues';
import { CreateChartTS } from '../../../../util/CreateChartTS';
import { getColorsForStakeholders, treemapRGB } from '../../../../../../helper/colors';
import GraphSetting from '../../../../options/components/GraphSettings';
import { applyValueType } from '../../../../options/util/applyOptions';
import ChartDownload from '../../../../options/components/ChartDownload';

interface ChartData {
    product: Product,
    selectedLCS: string,
    selectedLCSS: string,
    selectedProcess: string,
    selectedValueType: string,
}

function B4PiePSILCA(props: ChartData) {
    const classes = useStyles();
    const {
        product, selectedProcess, selectedLCS, selectedLCSS, selectedValueType,
    } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>();
    const [results, setResults] = useState<PSILCAResult[]>([]);

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => item.calculationType === 'SOCIAL-PSILCA'
        );
        const calcResults = calc?.calculationResults as PSILCAResult[];
        setResults(calcResults);
    }, [product]);

    useEffect(() => {
        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);

        if (options?.plugins?.datalabels) {
            options.plugins.datalabels.color = '#020202';
            options.plugins.datalabels.anchor = 'center';
            options.plugins.datalabels.align = 'center';
            options.plugins.datalabels.textShadowBlur = 5;
            options.plugins.datalabels.textShadowColor = 'rgba(0, 0, 0, 1)';
        }
        
        chartData.labels = results.map(
            (stakeholder) => stakeholder.stakeholder,
        );
        chartData.datasets = [
            {
                label: selectedProcess,
                data: results.map((stakeholder) => {
                    let stakeholderTotal = 0;
                    stakeholder.subcategories.forEach((subcategory) => {
                        let indicatorTotal = 0;
                        subcategory.indicators.forEach((indicator) => {
                            const matchingIndicator = indicator.indicatorResults.find(
                                (item) => item.lcsName === `${selectedLCS}:${selectedLCSS}`
                            );
                            if (matchingIndicator) {
                                const matchingProcess = matchingIndicator.processResults.processes.find(
                                    (item) => item.process === selectedProcess.split(' - ')[0]
                                );
                                if (matchingProcess) {
                                    indicatorTotal += matchingProcess.processTotalSum;
                                }
                            }
                        });
                        stakeholderTotal += indicatorTotal;
                    });
                    stakeholderTotal = Math.round(stakeholderTotal * 100) / 100;
                    return stakeholderTotal;
                }),
                // backgroundColor: treemapRGB.slice(0), 
                backgroundColor: getColorsForStakeholders(chartData.labels),
                borderWidth: 2,
                borderColor: "#FFFFFF",
            },
        ];

        delete options.scales;

        chartData.labels = results.map(
            (stakeholder) => stakeholder.stakeholder,
        );


        setChartJSData(chartData);
        setChartOptions(options);
    }, [selectedValueType, selectedProcess]);

    const [applyGraphSettings, setApplyGraphSettings] =
    useState<(options: ChartJSOptions, chartData: ChartJSData) =>
         { options: ChartJSOptions; chartData: ChartJSData; }>();    
    const triggerChartUpdate = () => {
        let options = deepClone(chartOptions) || {};
        let chartData = deepClone(chartJSData);

        if (options.scales && options.scales.y && options.scales.y.title) {
            options.scales.y.title.text = "Medium Risk Hours";
        }
        
        const applyOptionReturn = applyValueType(selectedValueType, chartData, options,false,'pie');
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        if(applyGraphSettings){

            
            ({ options, chartData } = applyGraphSettings(options, chartData));
        }
        console.log(chartData);
        CreateChartTS(chartData, chartOptions, 'pie', 'graph2Canvas');

    };

    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);

    return (
        <Box className={classes.chartSec}>
            <div className='settings-two-graph'>
                <ChartDownload chartData = {chartJSData} chartName={'Stakeholder level result - Process'} chartType='Pie Chart' />
                <GraphSetting
                    chartOptions={chartOptions}
                    setApplyGraphSettings={setApplyGraphSettings}
                    triggerChartUpdate={triggerChartUpdate}
                    chartData = {chartJSData}
                    chartColorType = 'treemap'
                    graphType='pie'
                />
            </div>
            <canvas id='graph2Canvas' className='graph-canvas' />
        </Box>
    );
}

export default B4PiePSILCA;
