import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import useStyles from './C1SDGStackedBarPSILCA.styles';
import { Bar } from 'react-chartjs-2';
// import Loader from '../../components/loader';
import toast from 'react-hot-toast';
import deepClone from '../../../../../../helper/deepClone';
import { ChartJSData, ChartJSOptions } from '../../../../../../interface/ChartData';
import PSILCAChartNavigation from '../../../../../dropdowns/BaseOptions/PSILCAChartNavigation';
import ResultsTheme from '../../../../style/ResultsTheme';
import { initialOptions } from '../../../../options/InitialOptionValues';
import ChartDownload from '../../../../options/components/ChartDownload';
import ProductComparison from '../../../../options/components/Comparison';
import GraphSetting from '../../../../options/components/GraphSettings';
import { applyStacked, applyValueType } from '../../../../options/util/applyOptions';
import { CreateChartTS } from '../../../../util/CreateChartTS';
import {
    PSILCAPSILCAPriorityResult, Product, PSILCALCSProcessResult,
    PSILCALifeCycleResult, PSILCAResult, PSILCAStakeholderResult,
    PSILCAProcessResult,
} from '../../../../../../interface/Product';
import Dropdown from '../../../../../dropdowns/Dropdown';
import { palette1, palette2, palette3, palette4, socialProcessColors } from '../../../../../../helper/colors';
import { useFormContext } from '../../../../../forms/FormContext';
import { WeighingSet, applyWeight, weighingMethods } from '../../../../../../helper/weighingMethod';
import { fetchEnvSDGs, fetchSocialSDGs } from '../../../../../../helper/fetchUtil';
import { noCaseCompare } from '../../../../../../helper/utils';
import { SDG } from '../../../../../../interface/SDG';
import autoRound from '../../../../../../helper/autoRound';
import SDGChecklist from '../../../../components/SDGChecklist';
import GraphType from '../../../../options/components/GraphTypeOptions';

interface ColorIndexData {
    label: string;
    identifier?: string;
}

interface ChartData {
    product: Product;
}

function C1SDGStackedBarPSILCA(props: ChartData) {
    const { formState, setFormState } = useFormContext();
    const classes = useStyles();
    const { product } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>();
    const [selectedValueType, setSelectedValueType] = useState<string>('Raw Values');
    const [stakeholderOptions, setStakeholderOptions] = useState<string[]>([]);
    const [loadedSDGs, setLoadedSDGs] = useState<SDG[]>([]);
    const [selectedSDGs, setSelectedSDGs] = useState<string[]>([]);

    const [results, setResults] = useState<PSILCAResult[]>([]);

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => { return item.calculationType === 'SOCIAL-PSILCA' },
        );
        const calcResults = calc?.calculationResults as PSILCAResult[];

        setResults((calcResults));
        try {
            fetchSocialSDGs().then((sdgs) => setLoadedSDGs(sdgs)).catch();
        } catch (err) {
            console.log(err);
        }
    }, [product]);




    const findColorPaletteByName = (name: string): string[] => {
        const palettes = {
            "Process Colors": socialProcessColors,
            "Sunset": palette1,
            "Multicolored": palette2,
            "Warm": palette3,
            "Cold": palette4,
        };
        return palettes[name] || palettes["Process Colors"];
    };

    const generateExtendedPalette = (baseColors: string[], totalColors: number): string[] => {
        const extendedPalette = [...baseColors];
        while (extendedPalette.length < totalColors) {
            baseColors.forEach((color) => {
                // Adjust brightness or saturation to generate new colors
                const newColor = adjustColor(color, extendedPalette.length / totalColors);
                extendedPalette.push(newColor);
                if (extendedPalette.length >= totalColors) return;
            });
        }
        return extendedPalette.slice(0, totalColors);
    };

    const adjustColor = (color: string, factor: number): string => {

        const f = parseInt(color.slice(1), 16),
            t = factor < 0 ? 0 : 255,
            p = factor < 0 ? factor * -1 : factor,
            R = f >> 16,
            G = f >> 8 & 0x00FF,
            B = f & 0x0000FF;
        return "#" + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
    };

    const getGraphColorIndex = useCallback(({ label, identifier }: ColorIndexData, palette: string[]) => {
        const hash = `${label}:${identifier}`.split('').reduce((hash, char) => {
            return char.charCodeAt(0) + ((hash << 5) - hash);
        }, 0);
        return Math.abs(hash) % palette.length;
    }, []);

    useEffect(() => {
        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);
        const selectedPalette = findColorPaletteByName(formState.colorPalette);

        if (options.scales && options.scales.y && options.scales.y.title) {
            options.scales.y.title.text = "Medium Risk Hours";
        }
        
        const processes: PSILCALifeCycleResult[] =
            results[0].subcategories[0].indicators[0].indicatorResults.reduce(
                (items: PSILCALifeCycleResult[], result) => {
                    items = items.concat(
                        result.processResults.processes.map((item) => {
                            return { lcs: result.lcsName, process: item.process };
                        })
                    );
                    return items;
                },
                []
            );
        chartData.datasets = processes.map((process, index) => {
            return {
                label: process.process,
                data: selectedSDGs.reduce((items: number[], sdg) => {
                    //Total Medium Risk Hours per sdg
                    const matchingSDG = loadedSDGs.find(
                        (item) => item.name === sdg
                    );
                    if (matchingSDG) {
                        items = items.concat(
                            matchingSDG.categories.reduce((total, category) => {
                                if (category.correlation.toLocaleString() !== "No Connection") {
                                    const matchingStakeholder = results.find(
                                        (item) =>
                                            item.stakeholder ===
                                            category.categoryName.split(":")[0]
                                    );
                                    if (matchingStakeholder) {
                                        const matchingCategory =
                                            matchingStakeholder.subcategories.find(
                                                (item) =>
                                                    item.subcategory ===
                                                    category.categoryName.split(":")[1]
                                            );
                                        if (matchingCategory) {
                                            let matchingLCS: PSILCALifeCycleResult;
                                            matchingCategory.indicators.forEach(
                                                (indicator) => {
                                                    matchingLCS =
                                                        indicator.indicatorResults.find(
                                                            (item2) =>
                                                                item2.lcsName === process.lcs
                                                        );
                                                    if (matchingLCS) {
                                                        const matchingProcess =
                                                            matchingLCS.processResults.processes.find(
                                                                (item) =>
                                                                    (item.process = process.process)
                                                            );
                                                        total +=
                                                            matchingProcess.processTotalSum;
                                                    }
                                                }
                                            );
                                        }
                                    }
                                }
                                return total;
                            }, 0)
                        );
                    }
                    return items;
                }, []),
                backgroundColor: selectedPalette[index] || "white", //"#EB9E43",//colors[index], //random color from array
                borderColor: "000000",
            };
        });
        chartData.labels = selectedSDGs;

   

        setChartJSData(chartData);
        setChartOptions(options);
    }, [selectedValueType, getGraphColorIndex, formState.colorPalette, loadedSDGs,results]);

    const [applyGraphSettings, setApplyGraphSettings] =
        useState<(options: ChartJSOptions, chartData: ChartJSData) =>
            { options: ChartJSOptions; chartData: ChartJSData; }>();
    const triggerChartUpdate = () => {
        let options = deepClone(chartOptions) || {};
        let chartData = deepClone(chartJSData);
        const applyOptionReturn = applyValueType(selectedValueType, chartData, options, true);
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        options = applyStacked(options, true);

        if (applyGraphSettings) {
            ({ options, chartData } = applyGraphSettings(options, chartData));
        }
        CreateChartTS(chartData, options);
    };

    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >
                <Box className='temp-box' />
                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '3rem',
                                        alignItems: 'stretch',

                                    }}
                                >
                                    <PSILCAChartNavigation product={product} defaultBaseIndex={2} defaultResultIndex={0} />
                                    <SDGChecklist
                                        setSelectedSDGs={setSelectedSDGs}
                                        disabled={false}
                                        setting="C1"
                                        dimension="PSILCA"
                                    />
                                    <ProductComparison />
                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >
                                        <GraphType
                                            chart='C1'
                                            dimension='PSILCA'
                                            type='stacked'
                                            sdgOn
                                        />
                                        <Dropdown
                                            options={['Raw Values', '% Per SDG']}
                                            valueSetter={setSelectedValueType}
                                            value={selectedValueType}
                                            noDefaults

                                        />

                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            '@media (max-width: 1680px)': {
                                                gap: '1vw',
                                            },
                                        }}
                                        className='bottom-options side-right'
                                    >
                                        <ChartDownload chartData={chartJSData}
                                            chartName={'Subcategory level result - Product System'}
                                            chartType='Bar Graph' />
                                        <GraphSetting
                                            chartOptions={chartOptions}
                                            setApplyGraphSettings={setApplyGraphSettings}
                                            triggerChartUpdate={triggerChartUpdate}
                                            chartData={chartJSData}
                                            chartColorType='performance'
                                            graphType='barindicator'
                                        />

                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box className={classes.chartSec}>
                            <canvas id='graph1Canvas' className='graph-canvas' />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default C1SDGStackedBarPSILCA;
