const autoRound = (value: number, decimalPlaces = 2): number => {
    if (value === 0) return 0; // Handle zero case directly

    const sign = Math.sign(value);
    const absValue = Math.abs(value);

    // Check the number of significant decimal places required to avoid rounding to zero
    const minDecimalPlaces = Math.ceil(Math.log10(1 / absValue));

    // Use the larger between the requested decimal places and the minimum necessary
    const precision = Math.max(decimalPlaces, minDecimalPlaces);

    // Round to the desired precision
    const factor = Math.pow(10, precision);
    const roundedValue = Math.round(absValue * factor) / factor;

    return sign * roundedValue;
};

export default autoRound;
