import { Box, Paper, Typography } from '@mui/material';
import { FormApi, useForm } from '@tanstack/react-form';
import React, { useEffect, useState } from 'react';
import { useFormContext } from '../../../../forms/FormContext';
import SocialInfoPopup from '../SocialInfoPopup';
import Dropdown from '../../../../dropdowns/Dropdown';
import useStyles from '../../SocialInputs.styles';
import { LifeCycleStage } from '../../../../../helper/lifeCycleStage';
import { useNavigate } from '@tanstack/react-router';
import { SocialInputsForm } from '../../../../../interface/Form';
interface SocialLCSDropdowns {
    currentLCS: string,
    currentLCSS: string,
    socialAssessmentMethod: string,

}
interface SocialLCSDropdownsProps {
    setSelectedLCS: React.Dispatch<React.SetStateAction<string>>,
    setSelectedLCSS: React.Dispatch<React.SetStateAction<string>>,
    selectedPSILCALCS?: string,
    selectedPSILCALCSS?: string,
    assessmentMethod?:string,
}
export const SocialInputsLCSDropdownsSPA = ({ setSelectedLCS, setSelectedLCSS,
    selectedPSILCALCS, selectedPSILCALCSS,assessmentMethod }: SocialLCSDropdownsProps) => {
    const navigate = useNavigate();

    const classes = useStyles();
    const { formState, setFormState } = useFormContext();
    const [selectableLCS, setSelectableLCS] = useState<string[]>([]);
    const [selectableLCSS, setSelectableLCSS] = useState<string[]>([]);
    const [selectedStages, setSelectedStages] = useState<LifeCycleStage[]>([]);
    const [selectedImpactMethod, setSelectedImpactMethod] = useState<string>(assessmentMethod || 'PSILCA');
    const [selectableMethods, setSelectableMethods] = useState<string[]>([
        'PSILCA',
        'SPA',
    ]);


    const form = useForm<SocialLCSDropdowns>({
        defaultValues: {
            currentLCS: formState.selectedLCS ? (formState.selectedLCS as string[])[0].split(':')[0] : 'Product Stage',
            currentLCSS: formState.selectedLCSS ? (formState.selectedLCSS as string[])[0].split(':')[1] : 'Raw Material Supply', // TODO use this way of getting default LCS in social as well
            socialAssessmentMethod: assessmentMethod || 'PSILCA',
        },
        onSubmit: ({ value }) => {
            // Do something with form data
            setFormState({
                ...formState,
            });

        },
    });

    const selectLCSHandler = (lcs: string, _stages?: LifeCycleStage[]) => {
        const stages = _stages || selectedStages;
        const stage = stages.find((_stage) => { return _stage.name === lcs });
        if (stage) {
            setSelectableLCSS(stage.substages.map((substage) => { return substage.name }));
            setSelectedLCSS(stage.substages[0].name);
            form.setFieldValue('currentLCSS', stage.substages[0].name);
        }
    };

    const fetchSelectedLCS = () => {
        if (formState && formState.selectedLCS) {
            const stages: LifeCycleStage[] = [];


            if(formState.selectedLCS.length <= 1 ){
                return;
            }
            
            (formState.selectedLCS as string[]).forEach((lcs: string) => {
                if (lcs === `${selectedPSILCALCS}:${selectedPSILCALCSS}`) {
                    return;
                }
                const matchingLCS = stages.find(
                    (stage) => { return stage.name === lcs.split(':')[0] },
                );

                if (matchingLCS) {
                    matchingLCS.substages.push({ name: lcs.split(':')[1] });
                } else {
                    stages.push({
                        name: lcs.split(':')[0],
                        substages: [{ name: lcs.split(':')[1] }],
                    });
                }
            });
            setSelectedStages(stages);
            let stage;
            if (form.state.values.currentLCS === selectedPSILCALCS && form.state.values.currentLCSS === selectedPSILCALCSS) {
                stage = stages.find((item) => item.name === selectedPSILCALCS); 


              
            } 
            if(!stage){
                stage = stages[0];

            }
            if(stage){
                form.setFieldValue('currentLCS', stage.name);
                setSelectedLCS(stage.name);
                setSelectableLCS(stages.map((stage) => { return stage.name }));
                selectLCSHandler(stage.name, stages);
            }
       


        } else {
            navigate({ to: '../../details' })
                .then((val) => { })
                .catch((err) => { });
        }
    };

    useEffect(() => {
        if (selectedPSILCALCS && selectedPSILCALCSS) {
            fetchSelectedLCS();

        }
    }, [selectedPSILCALCSS])


    const selectImpactMethodHandler = (method: string) => {
        if (method === 'SPA') {
            navigate({ to: '../spa' })
                .then((val) => { })
                .catch((err) => { });
        } else {
            navigate({ to: '../psilca' })
                .then((val) => { })
                .catch((err) => { });
        }
    }

    useEffect(() => {
        fetchSelectedLCS();
    }, [])

    return <>
        <Box
            sx={{
                display: 'flex',
                gap: '1vw',
                width: '-webkit-fill-available',
            }}
        >
            <Paper
                square
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'auto 1fr',
                    justifyItems: 'center',
                    alignItems: 'center',
                    width: '-webkit-fill-available',
                    '@media (max-width: 1680px)': {
                        gridTemplateColumns: 'auto auto',
                        gap: '1vw',
                        alignItems: 'center',
                    },
                }}
            >
                <Typography component='h2' variant='h2'>
                    Impact Assessment Method
                </Typography>
                <Box className={classes.IAMWrapper}>
                    <form.Field
                        name='socialAssessmentMethod'
                        children={(field) => {
                            return (
                                <Dropdown
                                    options={selectableMethods}
                                    onChange={(e) => {
                                        field.handleChange(e.target.value);
                                        setSelectedImpactMethod(e.target.value);
                                        selectImpactMethodHandler(e.target.value);
                                    }}
                                    id={field.name}
                                    className={`${field.name} iam`}
                                    value={field.state.value}
                                    onBlur={field.handleBlur}
                                    label='Method Stage'
                                    noDefaults

                                />
                            )
                        }}
                    />

                    <SocialInfoPopup
                        selectedImpactMethod={selectedImpactMethod}
                    />
                </Box>
            </Paper>
            <Paper
                square
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'auto 1fr',
                    justifyItems: 'center',
                    alignItems: 'center',
                    width: '-webkit-fill-available',
                }}
            >
                <Typography component='h2' variant='h2'>
                    Lifecycle Stages
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '2vw',
                        // '@media (max-width: 1680px)': {
                        //     flexDirection: 'column',
                        //     gap: '5px',
                        // },
                    }}
                >
                    <form.Field
                        name='currentLCS'
                        children={(field) => {
                            return (
                                <Dropdown
                                    options={selectableLCS}
                                    onChange={(e) => {
                                        field.handleChange(e.target.value);
                                        selectLCSHandler(e.target.value);
                                        setSelectedLCS(e.target.value);
                                    }}
                                    id={field.name}
                                    className={field.name}
                                    value={field.state.value}
                                    onBlur={field.handleBlur}
                                    label='Lifecycle Stage'
                                    noDefaults
                                />
                            )
                        }}
                    />
                    <form.Field
                        name='currentLCSS'
                        children={(field) => {
                            return (
                                <Dropdown
                                    options={selectableLCSS}
                                    onChange={(e) => {
                                        field.handleChange(e.target.value);
                                        setSelectedLCSS(e.target.value);
                                    }}
                                    id={field.name}
                                    className={field.name}
                                    value={field.state.value}
                                    onBlur={field.handleBlur}
                                    label='Sublifecycle Stage'
                                    noDefaults
                                />
                            )
                        }}
                    />
                </Box>
            </Paper>
        </Box>
    </>
}