import { ThemeProvider } from '@emotion/react';
import { Button } from '@mui/base';
import { TextField, TableCell, TableRow, styled, Box } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { FormApi, useForm } from '@tanstack/react-form';
import {
    EnvironmentalInputsForm,
    EnvInputProcess,
    SocialInputsForm,
    SocialInputProcess,
    SocialInputsLCS,
} from '../../../../../interface/Form';
import AddButton from '../../../../style/components/AddButton';
import ExitButton from '../../../../style/components/ExitButton';
import { useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { SelectProcess } from '../../../../../interface/Process';
import { UnitGroup, findUnitUnitGroup } from '../../../../../interface/Unit';
import Dropdown from '../../../../dropdowns/Dropdown';
import useStyles from './SocialInputProcessListItem.styles';

interface ProcessListItemProps {
  openProcessSelect: (event: React.MouseEvent<HTMLElement>) => void;
  index: number;
  socialForm: FormApi<SocialInputsForm>;
  process: SocialInputProcess;
  setSelectHandler: React.Dispatch<
    React.SetStateAction<(process: SelectProcess) => void>
  >;
  lifeCycleStage: string;
  deleteHandler: (processIndex: number) => void;
spaOn?: boolean;
}

export function SocialInputProcessListItem({
    openProcessSelect,
    index,
    socialForm,
    process,
    setSelectHandler,
    lifeCycleStage,
    deleteHandler,
    spaOn,
}: ProcessListItemProps) {
    const form = useForm({
        defaultValues: {
            index,
            endProductName: process.endProductName,
            processId: process.processId,
            processName: process.processName,
            processQuantity: process.processQuantity || 1,
            processComment: process.processComment,
            category: process.category,
        },
        onSubmit: ({ value }) => {
            // Do something with form data
            console.log(value);
        },
    });

    const InputTableRow = styled(TableRow)(({ theme }) => {return {
        padding: '5px',
        fontSize: '12px',
        color: '#707070',
        '&:nth-of-type(odd)': {
            backgroundColor: '#F3F3F3',
        },
        '&:nth-of-type(even)': {
            backgroundColor: '#E6E6E6',
        },
    }});

    const SmallerDeleteButton = styled(Button)(({ theme }) => {return {
        // minHeight: '16px',
        // minWidth: '16px',
        marginTop: '0',
        marginRight: '0',
        padding: '5px',
        border: '0',
        borderRadius: '50px',
        boxShadow: 'none',
        fontSize: '0.7vw',
        width: 'auto',
        backgroundColor: '#B85959',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            backgroundColor: '#EFC4C4',
            boxShadow: 'none',
        },
        '@media (max-width: 1680px)': {
            padding: '2px',
        },
    }});

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const [selectedProcess, setSelectedProcess] = useState<SelectProcess>({
        name: process.processName.split(' - ')[0],
        id: process.processId,
        location: process.processName.split(' - ')[1],
        category: process.category,
    });
    const [selectableUnits, setSelectableUnits] = useState<string[]>([]);
    const [selectableUnitValues, setSelectableUnitValues] = useState<string[]>(
        []
    );

    const selectEditProcessHandler = (_process: SelectProcess) => {
        setSelectedProcess(_process);
        form.setFieldValue('processId', _process.id);
        form.setFieldValue('processName', _process.name);
    };

    const classes = useStyles();

    const triggerUpdate = () => {
        const stages: SocialInputsLCS[] = socialForm.getFieldValue(
            'socialInputProcesses'
        );
        const matchingLCS = stages.find((stage) => {return stage.lcs === lifeCycleStage});
        if (matchingLCS) {
            const currentProcesses = matchingLCS.values;

            const matchingProcess = currentProcesses.find((item,itemIndex) => {return itemIndex === index});

            const endProductName = form.getFieldValue('endProductName');
            const processId = selectedProcess.id;
            const processName = form.getFieldValue('processName');
            const processQuantity = form.getFieldValue('processQuantity');
            const processComment = form.getFieldValue('processComment');
            const category = form.getFieldValue('category');
            const updatedProcess: SocialInputProcess = {
                index,
                endProductName,
                processId,
                processName,
                processQuantity,
                processComment,
                category,
            };
            if (matchingProcess) {
                matchingLCS.values.splice(index, 1, updatedProcess);
            } else {
                matchingLCS.values.push(updatedProcess);
            }
            socialForm.setFieldValue('socialInputProcesses', stages);
        }
    };


    return (
        <>
            <TableRow
                sx={{
                    // display: 'flex',
                    // flexDirection: 'row',
                    display: 'grid',
                    gridTemplateColumns: spaOn ? '1fr 2fr 1fr 2vw' : '1fr 2fr 1fr 1fr 5rem',
                    fontSize: '12px',
                    alignItems: 'center',
                    color: '#707070',
                    '&:nth-of-type(odd)': {
                        backgroundColor: '#F3F3F3',
                    },
                    '&:nth-of-type(even)': {
                        backgroundColor: '#E6E6E6',
                    },
                }}
            >
                <button type='button' style={{ position: 'absolute' }}>
          TEST
                </button>
                <TableCell className={classes.InputTableCell}>
                    <form.Field
                        name='endProductName'
                        children={(field) => {return (
                            <TextField
                                id={field.name}
                                className={field.name}
                                value={field.state.value}
                                onBlur={field.handleBlur}
                                onChange={(e) => {
                                    field.handleChange(e.target.value);
                                    triggerUpdate();
                                }}
                                size='small'
                                sx={{
                                    width: "-webkit-fill-available",
                                    padding: '2px',
                                    '&.MuiInputBase-input': {
                                        padding: '5.5px 12px',
                                    },
                                }}
                            />
                        )}}
                    />
                </TableCell>
                <TableCell className={classes.InputTableCell}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                lineHeight: '16px',
                                whiteSpace: 'nowrap',
                                maxWidth: '20vw',
                                overflowX: 'auto',
                                '::-webkit-scrollbar': {
                                    width: '0.2em',
                                },
                                '::-webkit-scrollbar-track': {
                                    background: '#939598',
                                    border: 0,
                                },
                                '::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#32463e',
                                    border: 0,
                                },
                            }}
                        >
                            {selectedProcess && selectedProcess.name}
                        </Box>

                        <ThemeProvider theme={AddButton}>
                            <Button
                                onClick={(e) => {
                                    setSelectHandler(() => {return selectEditProcessHandler});
                                    openProcessSelect(e);
                                }}
                                style={{
                                    border: 0,
                                    background: 0,
                                }}
                            >
                                <EditIcon sx={{ color: '#707070' }} />
                            </Button>
                        </ThemeProvider>
                    </div>
                </TableCell>
                {!spaOn && (
                    <TableCell className={classes.InputTableCell}>
                        <form.Field
                            name='processQuantity'
                            children={(field) => {return (
                                <TextField
                                    id={field.name}
                                    className={field.name}
                                    value={field.state.value}
                                    onBlur={field.handleBlur}
                                    onChange={(e) => {
                                        field.handleChange(Number(e.target.value));
                                        triggerUpdate();
                                    }}
                                    required
                                    type='number'
                                    defaultValue={0}
                                    size='small'
                                // sx={{
                                //     width: '25%',
                                // }}
                                />
                            )}}
                        />
                    </TableCell>
                )}
                <TableCell className={classes.InputTableCell}>
                    <form.Field
                        name='processComment'
                        children={(field) => {return (
                            <TextField
                                id={field.name}
                                className={field.name}
                                value={field.state.value}
                                onBlur={field.handleBlur}
                                onChange={(e) => {
                                    field.handleChange(e.target.value);
                                    triggerUpdate();
                                }}
                                size='small'
                                sx={{
                                    width: "-webkit-fill-available",
                                    padding: '2px',
                                    '&.MuiInputBase-input': {
                                        padding: '5.5px 12px',
                                    },
                                }}
                            />
                        )}}
                    />
                </TableCell>
                <TableCell
                    className={classes.InputTableCell}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <ThemeProvider theme={ExitButton}>
                        <SmallerDeleteButton onClick={ () =>{deleteHandler(index)}}>
                            <ClearIcon sx={{ fontSize: '0.7vw' }} />
                        </SmallerDeleteButton>
                    </ThemeProvider>
                </TableCell>
            </TableRow>

            {/* <InputTableRow>

                <TableCell align='center' />

                <TableCell align='center'>
                    Test
                    <ThemeProvider theme={AddButton}>
                        <Button onClick={openProcessSelect}>
                            <EditIcon sx={{ color: '#707070' }} />
                        </Button>
                    </ThemeProvider>
                </TableCell>
                <TableCell align='center'>Test</TableCell>
                <TableCell align='center'>Test</TableCell>
                <TableCell align='center'>Test</TableCell>
                <TableCell align='center'>
                    <ThemeProvider theme={ExitButton}>
                        <SmallerDeleteButton>
                            <ClearIcon sx={{ fontSize: '16px' }} />
                        </SmallerDeleteButton>
                    </ThemeProvider>
                </TableCell>

            </InputTableRow> */}
        </>
    );
}
