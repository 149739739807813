import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => {return {
    chartSec: {
        width: '100%',
        height: '63vh',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    SunburstWrap: {

    },
    SunburstLegendWrap: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1vw',
        fontFamily: 'Montserrat, sans-serif',
        width: '15vw',
        alignItems: 'flex-start',
    }, 
    SunburstLegendBox: {
        display: 'flex',
        flexDirection: 'row',
        gap: '5px',
    },
    SunburstLegendText: {
        fontFamily: 'Montserrat, sans-serif !important',
        fontSize: '0.7vw !important',
    },
}});

export default useStyles;
